import React,{useState} from 'react'
import {LoginContainer, TopContainer, NavBtnLink, Form, FormWapper, FormInput, Label, LabelLargeCover, LabelLarge, LabelWapper, ButtonWrap, EachButtonWrap} from './indexElements'
import axios from 'axios'
import {Button} from '../../../components/ButtonElements'
import { Navigate } from "react-router-dom";
import {delay} from '../../../utils/methods'
const Login = () => {

    const initialInput = {
        email:"",
        password:"",
        retypePassword:"",
        code:""
    }

    const [input, setInput] = useState(initialInput)
    const [errors, setErrors] = useState({})
    const [success, setSuccess] = useState(false)
    const [failed, setFailed] = useState(false)
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [isShow, setIsShow] = useState(false) 
    const [buttonLabel, setButtonLabel] = useState("Get New Code")
    const [displayReponse, setDisplayResponse] = useState('')
    const [authenticated, setauthenticated] = useState(false);

    const handleChange = (e) =>{
        const {name, value} = e.target;
        setInput({...input, [name]: value}) 
    }

    const validation = (value) =>{
        const validateError = {};
        if(!value.email && !isShow){
            validateError.email = "email is required"
        }
        if(!value.code && isShow){
            validateError.code= "Code is required"
        }
        if(!value.password && isShow){
            validateError.password= "Password is required"
        }
        if(!value.retypePassword && isShow){
            validateError.retypePassword= "Retype Password is required"
        }
        if(value.retypePassword && isShow){
            if(value.retypePassword !== value.password)
            {
                validateError.retypePassword= "Passwords are not matched"
            }    
        }
        return validateError
    }
    
    const handleSubmit = async (e) => {
        e.preventDefault()
        const newErrors = validation(input)
        setErrors(newErrors)
        if(Object.keys(newErrors).length === 0){
            if(isShow)
            {
                const user = {
                    email: input.email,
                    password: input.password,
                    code:input.code,
                }
                try{
                  await axios.post('https://fabackend.herokuapp.com/users/reset-password',user)
                  .then(async(respond)=>{
                    setSuccess(true)
                    setFailed(false)
                    setIsSubmitted(true)
                    setInput(initialInput)
                    await delay(3000)
                    setSuccess(false)
                    setIsSubmitted(false)
                    await delay(2000)
                    setauthenticated(true)
                    console.log(respond)
                  })
                }catch(err){
                    setFailed(true)
                    setDisplayResponse(err.response.data)
                    await delay(3000)
                    setFailed(false)
                    setDisplayResponse("")
                    await delay(2000)
                }
            }else{
                const emailCode = {
                    email: input.email,
                }
                try{
                  await axios.post('https://fabackend.herokuapp.com/emailcode/newcode',emailCode)
                  .then(()=>{
                    setIsShow(true)
                    setButtonLabel("Change Password")
                  })
                }catch(err){
                    setFailed(true)
                    setIsShow(false)
                    setDisplayResponse(err.response.data)
                    await delay(2000)
                    setFailed(false)
                    setDisplayResponse("")
                    await delay(2000) 
                }
            }

        }
    }
    if (authenticated) {
        return <Navigate replace to="/internal-fa/signin" />;
    } else 
    {
        return (
            <LoginContainer>

                <Form onSubmit={handleSubmit}>
                    <LabelLargeCover>
                        <LabelLarge>New Password</LabelLarge>
                    </LabelLargeCover>
                        {failed?
                            <button
                                onClick={()=>setFailed(false)}
                                className={`alert alert-danger`}
                                >
                                <strong>Failed!</strong> {displayReponse}
                            </button>  
                            :
                            <div></div>
                        }
                        {
                        isSubmitted && success && Object.keys(errors).length ===0?
                        <button
                        onClick={()=>setSuccess(false)}
                        className={`alert alert-success`}
                        >
                        <strong>Success! </strong> You succesfully changed your password 
                        </button>  
                        :
                        <div></div>
                                
                    }
                    {!isShow?
                        <FormWapper>
                        <LabelWapper><Label>Email</Label></LabelWapper>
                        <FormInput name='email' placeholder="Your Email" value={input.email} onChange={handleChange}/>
                        {errors.email?
                        <div className="text-danger  m-2">{errors.email}</div>
                        :
                        <div></div>
                        }
                        </FormWapper>
                        :
                        <></>
                    }
                    {isShow?
                        <>
                        <FormWapper>
                        <LabelWapper><Label>Password</Label></LabelWapper>
                        <FormInput name='password' placeholder="Your Password" value={input.password} onChange={handleChange}/>
                        {errors.password?
                        <div className="text-danger  m-2">{errors.password}</div>
                        :
                        <div></div>
                        }
                        </FormWapper>
                        <FormWapper>
                        <LabelWapper><Label>Retype Password</Label></LabelWapper>
                        <FormInput name='retypePassword' placeholder="Retype your Password" value={input.retypePassword} onChange={handleChange}/>
                        {errors.retypePassword?
                        <div className="text-danger  m-2">{errors.retypePassword}</div>
                        :
                        <div></div>
                        }
                        </FormWapper>
                        <FormWapper>
                        <LabelWapper><Label>Code</Label></LabelWapper>
                        <FormInput name='code' placeholder="Your new code" value={input.code} onChange={handleChange}/>
                        {errors.code?
                        <div className="text-danger  m-2">{errors.code}</div>
                        :
                        <div></div>
                        }
                        </FormWapper>
                        </>               
                        :
                        <></>
                    }
                <ButtonWrap>
                    <EachButtonWrap>
                        <Button fontBig primary dark = {true}>{buttonLabel}</Button>
                    </EachButtonWrap>                
                </ButtonWrap>
                </Form>
                <TopContainer>
                    <NavBtnLink to = "/internal-fa/signin">remmember password?</NavBtnLink>
                </TopContainer>
            </LoginContainer>
        )
    }
}

export default Login