import React from 'react';
import { FaBars} from 'react-icons/fa'
import { Nav, NavLogo, NavbarContainer, NavItem, NavMenu, NavLinks, MobileIcon, NavBtn} from './NavbarInternalElements';
import {Button} from '../../../components/ButtonElements'
import {useSignOut} from 'react-auth-kit'
function NavInternal ({toggle}) {
  const signOut = useSignOut();

  return (
    <>
      <Nav>
        <NavbarContainer>
            <NavLogo to="/internal-fa">
              FA-Internal
            </NavLogo>
            <MobileIcon onClick={toggle}>
              <FaBars />
            </MobileIcon>
            <NavMenu>
              <NavItem>
                <NavLinks to ="/internal-fa/Open"> Open </NavLinks>
              </NavItem>  
              <NavItem>
                <NavLinks to ="/internal-fa/Pending"> Pending </NavLinks>
              </NavItem>            
              <NavItem>
                <NavLinks to ="/internal-fa/Confirmed"> Confirmed </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to ="/internal-fa/Archived">Archived </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to ="/internal-fa/reviews">Reviews </NavLinks>
              </NavItem>
            </NavMenu>
            <NavBtn>
              <Button fontBig primary dark = {true} onClick={signOut}>Log Out</Button>
            </NavBtn>
        </NavbarContainer>
      </Nav>
    </>
  );
}

export default NavInternal;

