import React, {useState, useRef, useEffect} from 'react'
import { HeroContainer, HeroBg, VideoBg,HeroContent, HeroH1, HeroP, HeroBtnWrapper, ArrowForward, ArrowRight } from './HeroElements'
import { Button, ButtonLinks } from '../ButtonElements'
import video from '../../videos/video.mp4'

const Hero = () => {

    const videoBg = useRef(null);

    const [hover, setHover] = useState(false)

    const onHover = () =>{
        setHover(!hover)
    }

    useEffect(() => {
        const video = videoBg.current;
        video.play()
      }, []);
     
  return (
    <>
        <HeroContainer id = "home">
            <HeroBg>
                <VideoBg ref={videoBg} playsInline loop muted src={video} type='video/mp4' />
            </HeroBg>   
            <HeroContent>
                <HeroH1>FA Tutoring</HeroH1>
                <HeroP primary ='true'>We are committed to go all in to ensure every student needs are met</HeroP>
                <HeroP>
                    Sign up to let us help your children
                </HeroP>
                <HeroBtnWrapper>
                    <ButtonLinks to ="about">
                        <Button onMouseEnter={onHover} onMouseLeave={onHover} primary = 'true' dark = 'true'>
                            Get Started {hover ? <ArrowForward /> :<ArrowRight/>}
                        </Button>
                    </ButtonLinks>
                </HeroBtnWrapper>
            </HeroContent>      
        </HeroContainer>
    </>
  )
}

export default Hero