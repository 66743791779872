import React, {useState} from 'react'
import CollapsibleTable from '../../components/Internal/components/ColapsibleTable/ColapsibleTable'
import NavInternal from '../../components/Internal/NavInternal'
import SideBarInternal from '../../components/Internal/SideBarInternal'
import {OrderTableContainer, OrderTableLabel} from '../../components/Elements/OrderElements'
import "bootstrap/dist/css/bootstrap.min.css"

const OpenOrder = () => {

  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }
  return (
    <>
      <SideBarInternal isOpen={isOpen} toggle={toggle}/>
      <NavInternal toggle={toggle}/>
      <OrderTableContainer>
        <OrderTableLabel>
          Open Order
        </OrderTableLabel>
        <CollapsibleTable isAdjustable={true} isOpen={true}/>
      </OrderTableContainer>
    </>
  )
}

export default OpenOrder