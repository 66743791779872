import React from 'react'
import moment from 'moment'
import {  
  ReviewContainer,
  ReviewHeaderMid, 
  ReviewDecription, 
  ReviewDisplayContainer, 
  ReviewDisplay, 
  ReviewDisplayMessage, 
  ReviewDisplayNameContainer, 
  ReviewDisplayStars,
  ReviewDisplayName, 
  ReviewDisplayDate,
  ReviewDisplayEmptyStar,
  ReviewDisplayFilledStar,
  ButtonCover,
  ButtonCoverWrap
  } from './ReviewHeaderElements' 
import {Button} from '../../../components/ButtonElements'

const Reviews = ({isAddReviewModalOpened,handleSetIsAddReviewModalOpened, reviews}) => {

  const reviewResult = []

  const onClick = () =>{
    if(!isAddReviewModalOpened){
        handleSetIsAddReviewModalOpened();
    }
  }
  reviews = reviews.filter(review=>review.status === "Confirmed")
  reviews.forEach((review, index)=>{
    const emptyStarResult= []
    const filledStarResult = []
    for(let i=0; i<Math.round(review.star);i++){
      filledStarResult.push(
        <ReviewDisplayFilledStar key={i}/>
      )
    }
    for(let i=0; i<(5-Math.round(review.star));i++){
      emptyStarResult.push(
        <ReviewDisplayEmptyStar key={i}/>
      )
    }
    if(index%2 === 0){
      reviewResult.push(
        <ReviewDisplay key={index} left={true}>
          <ReviewDisplayNameContainer left={true}>
            <ReviewDisplayName>{review.name}</ReviewDisplayName>
            <ReviewDisplayStars>
              {filledStarResult}{emptyStarResult}
            </ReviewDisplayStars>
          </ReviewDisplayNameContainer>
          <ReviewDisplayDate>{moment(review.date).format('YYYY-MM-DD hh:mm:ss')}</ReviewDisplayDate>
          <ReviewDisplayMessage>"{review.review}"</ReviewDisplayMessage>
        </ReviewDisplay>
      )
    }else{
      reviewResult.push(
        <ReviewDisplay key={index}>
         <ReviewDisplayNameContainer>
            <ReviewDisplayName>{review.name}</ReviewDisplayName>
            <ReviewDisplayStars>
              {filledStarResult}{emptyStarResult}
            </ReviewDisplayStars>
          </ReviewDisplayNameContainer>
          <ReviewDisplayDate>{moment(review.date).format('YYYY-MM-DD hh:mm:ss')}</ReviewDisplayDate>
          <ReviewDisplayMessage>"{review.review}"</ReviewDisplayMessage>
        </ReviewDisplay>
      )
    }
  })

  return (
    <ReviewContainer id='reviews'>
      <ReviewHeaderMid>Reviews</ReviewHeaderMid>
      <ReviewDisplayContainer>
        {reviewResult}
      </ReviewDisplayContainer>
      <ReviewDecription>" Thank you for chosing FA Tutoring services. We have helped many students to archive their target, yet we need all of your feedbacks to improve ourselves in order to provide a better service. Please send us your thoughts" - FA Tutoring CEO</ReviewDecription>
      <ButtonCoverWrap>
        <ButtonCover>
          <Button 
            primary={true} 
            onClick={onClick} 
            dark={true}
            >
              Write Us A Review
          </Button>
        </ButtonCover>
      </ButtonCoverWrap>

    </ReviewContainer> 

  )
}

export default Reviews