import React from 'react';
import { Button, ButtonLinks } from '../ButtonElements';
import { VscBook, VscExtensions } from 'react-icons/vsc';
import { IconContext } from 'react-icons/lib';
import {
  PricingSection,
  PricingWrapper,
  PricingHeading,
  PricingContainer,
  PricingCard,
  PricingCardInfo,
  PricingCardIcon,
  PricingCardPlan,
  PricingCardCost,
  PricingCardFeatures,
  PricingCardFeature
} from './PackageElements';

function Package({setPackages}) {

    const handleBasicPackageChoosen = () =>{
        setPackages('basic')
    }
    const handleComprehensivePackageChoosen = () =>{
        setPackages('comprehensive')
    }

  return (
    <IconContext.Provider value={{ color: '#a9b3c1', size: 64 }}>
      <PricingSection id='packages'>
        <PricingWrapper>
          <PricingHeading>Our Packages</PricingHeading>
          <PricingContainer>
            <PricingCard>
              <PricingCardInfo>
                <PricingCardIcon>
                  <VscBook />
                </PricingCardIcon>
                <PricingCardPlan>Basic</PricingCardPlan>
                <PricingCardCost>~-----------------~</PricingCardCost>
                <PricingCardFeatures>
                  <PricingCardFeature>Private Tutoring at home</PricingCardFeature>
                  <PricingCardFeature>Online Tutoring</PricingCardFeature>
                  <PricingCardFeature>Additional Traveling Fee</PricingCardFeature>
                  <PricingCardFeature> ... </PricingCardFeature>
                </PricingCardFeatures>
                <PricingCardCost orange={true}> 30$/hour</PricingCardCost>
                <ButtonLinks to='contact'>
                    <Button onClick={()=>{handleBasicPackageChoosen()}} primary>Choose</Button>
                </ButtonLinks>     
              </PricingCardInfo>
            </PricingCard>
            <PricingCard>
              <PricingCardInfo>
                <PricingCardIcon>
                  <VscExtensions />
                </PricingCardIcon>
                <PricingCardPlan>Comprehensive</PricingCardPlan>
                <PricingCardCost>~-----------------~</PricingCardCost>
                <PricingCardFeatures>
                  <PricingCardFeature>Private Tutoring at home</PricingCardFeature>
                  <PricingCardFeature>Online Tutoring</PricingCardFeature>
                  <PricingCardFeature>No Traveling Fees</PricingCardFeature>
                  <PricingCardFeature> 24/7 Online Assistance</PricingCardFeature>
                </PricingCardFeatures>
                <PricingCardCost orange={true}> 50$/hour</PricingCardCost>
                <ButtonLinks to='contact'>
                    <Button onClick={()=>{handleComprehensivePackageChoosen()}} primary>Choose</Button>
                </ButtonLinks>   
              </PricingCardInfo>
            </PricingCard>            
          </PricingContainer>
        </PricingWrapper>
      </PricingSection>
    </IconContext.Provider>
  );
}
export default Package;