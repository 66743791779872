import React from 'react'
import CollapsibleTable from '../../components/Internal/components/ColapsibleTable/ColapsibleTable'
//import openOrderContainer from '../../components/Elements/OpenOrderElements'
import "bootstrap/dist/css/bootstrap.min.css"
import {OrderTableContainer, OrderTableLabel} from '../../components/Elements/OrderElements'
const AllOrder = () => {

  return (
    <>
      <OrderTableContainer>
        <OrderTableLabel>
          All Order - Need more work on
        </OrderTableLabel>
        <CollapsibleTable/>
      </OrderTableContainer>
    </>
  )
}

export default AllOrder