import styled from 'styled-components';
import { AiFillStar, AiOutlineStar} from 'react-icons/ai'

export const ReviewContainer = styled.div`
    display: grid;
    justify-content: center;
    align-items:center;
    padding-top: 50px;
    background-color: #fff;
    border-radius: 10px 0px 0px 0px;
    box-shadow: ${({primary}) => (primary ? '0px 1px 7px #01BF71': '0px 2px 10px #010606')};
`
export const ReviewHeaderTop = styled.h1`
    display: flex;
    justify-content: center;
    align-items:center;
    font-size: 1.4rem;
    font-weight: 600;
    padding-top: 50px;
    color: #000;
`

export const ReviewHeaderMid = styled.h2`
    display: flex;
    justify-content: center;
    align-items:center;
    font-size: 2rem;
    font-weight: 800;
    padding-top: 10px;
    color: #01BF71;
    padding-bottom: 30px;
`

export const ReviewDecription = styled.p`
    font-size: 1rem;      
    color: #000;
    font-weight: 300;
    width: 900px;
    font-style: italic;
    margin-top: 40px;
    @media screen and ( max-width: 1200px){
        width: 550px;
    }
    @media screen and ( max-width: 768px){
        width: 350px;
    }
    
`
export const ReviewDisplayContainer = styled.div`
    display: grid;
    justify-content: center;
    align-items:center;
    padding: 2px 10px;
    border-radius: 10px 0px;
    border-color: #01BF71;
    border-style: solid double solid double;
`

export const ReviewDisplay = styled.div`
    text-align: ${({left})=>(left? 'left': 'right')};
    display: grid;
    width:800px;
    margin-bottom: 10px;
    @media screen and ( max-width: 1200px){
        width: 550px;
    }
    @media screen and ( max-width: 768px){
        width: 350px;
    }
`

export const ReviewDisplayNameContainer = styled.div`
    padding-top: 20px;
    display: flex;
    align-items: center;
    flex-direction: ${({left})=>(left? 'row': 'row-reverse')};;
`

export const ReviewDisplayName = styled.h2`
    font-size: 1.4rem;
    font-weight: 600;
    color: #000;
    padding-right: 10px;
`

export const ReviewDisplayStars = styled.h2`
    font-size: 1rem;      
    color: #DD7E28;
    padding-right: 10px;
`

export const ReviewDisplayMessage = styled.p`
    font-size: 1rem;
    font-weight: 700;      
    color: #000;
    font-style: italic;
`

export const ReviewDisplayDate = styled.h2`
    font-size: 1rem;      
    color: #01BF71;
    font-style: oblique;
`
export const ReviewDisplayEmptyStar = styled(AiOutlineStar)`

`

export const ReviewDisplayFilledStar = styled(AiFillStar)`

`
export const ButtonCover = styled.div`
    @media screen and ( max-width: 768px){
        display: none;
    }
`
export const ButtonCoverWrap = styled.div`
    display:grid;
    justify-content: center;
    align-items: center;
    margin-top: 40px ;
    margin-bottom: 50px;

`
