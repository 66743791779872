import styled from 'styled-components'
import { AiFillStar, AiOutlineStar} from 'react-icons/ai'
import {Link as LinkS} from 'react-scroll'

export const RatingContainer = styled.div`
    display: grid;
    justify-content:center;
    align-items: center;
    height: 950px;
`
export const RatingTopCover = styled.div`
    margin-top: 50px;
    padding: 0px 7px ;
    background-color: #01BF71;
    border-radius: 15px 0 15px 0;
    box-shadow:0px 2px 10px #010606;
`
export const RatingTop = styled.h1`
    display: flex;
    justify-content: center;
    align-items:center;
    font-size: 2.5rem;
    font-style: oblique;
    font-weight: 600;
    margin-top: 20px ;
    color: #000;
`

export const RatingPicture = styled.picture`

`
export const RatingHeadlineCover = styled.div`
`
export const RatingHeadline = styled.h1`
    display: flex;
    justify-content: center;
    align-items:center;
    font-size: 2rem;
    font-weight: 800;
    margin-bottom: 20px;
    color: #01BF71;
`

export const RatingAndReviewsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 450px;
    @media screen and ( max-width: 1200px){
        width: 400px;
    }
    @media screen and ( max-width: 768px){
        width: 350px;
    }
`

export const RatingCover = styled.div`
     display: flex;
     flex-direction: column;
     justify-content: space-around;
     align-items: center;
`
export const RatingLabel = styled.h2`
    font-style:oblique;
`
export const RateStarCover = styled.div`
    display: flex;
    color: #DD7E28;
`

export const RatingStar = styled(AiOutlineStar)`

`

export const RatingFilledStar = styled(AiFillStar)`

`

export const RatingLinks = styled(LinkS)`
    color: #000;

`

export const RecommendCover = styled.div`
     display: flex;
     flex-direction: column;
`

export const RecommendPercentage = styled.h2`
    font-style:oblique;
`

export const RecommendDesc = styled.p`
    max-width: 200px;
`

export const CategoriesContainer = styled.div`
    display: grid;
    justify-content: center;
    align-items: center;
    margin-bottom:50px;
`

export const CategoriesCover = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:flex-start;
    width: 450px;
    margin-bottom:20px;
    margin-top: 20px;
    @media screen and ( max-width: 1200px){
        width: 400px;
    }
    @media screen and ( max-width: 768px){
        width: 350px;
    }
`

export const CategoriesLabelCoverOut = styled.div`

`
export const CategoriesLabelCoverIn = styled.div`

`

export const CategoriesLabel = styled.h2`
    margin-bottom: -10px;
    margin-top: -10px;
    font-size: 1.3rem;
    font-weight: 700;
`
export const CategoriesLabel2 = styled.p`
    margin-bottom: -10px;
    margin-top: -10px;
    font-size: 0.9rem;
    font-weight: 700;
`

export const CategoriesBar = styled.input`
    height: 41px;
    -webkit-appearance: none;
    margin: 10px 0;
    width: 100%;

    &:focus{
        outline: none;
    }

    &::-webkit-slider-runnable-track{
        width: 100%;
        height: 2px;
        box-shadow: 1px 1px 1px #000000;
        background: #000;
        border-radius: 5px;
        border: 1px solid #000000;
    }

    &::-webkit-slider-thumb{
        box-shadow: 1px 1px 1px #000000;
        border: 1px solid #000000;
        height: 20px;
        width: 10px;
        border-radius: 13px;
        background: #fff;
        -webkit-appearance: none;
        margin-top: -9px;
    }

    &:focus::-webkit-slider-runnable-track{
        background: #3071A9;
    }

    &::-moz-range-track{
        width: 100%;
        height: 10px;
        cursor: pointer;
        box-shadow: 1px 1px 1px #000000;
        background: #3071A9;
        border-radius: 5px;
        border: 1px solid #000000;
    }
`

export const CategoriesBottom = styled.div`
    display: flex;
    justify-content: space-between;
    width: 450px;
    @media screen and ( max-width: 1200px){
        width: 400px;
    }
    @media screen and ( max-width: 768px){
        width: 350px;
    }
`
