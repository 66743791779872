import './App.css';

import React  from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Home from './pages';
import HomeInternal from './pages/internal';
import OpenOrder from './pages/internal/OpenOrder';
import SignUp from '../src/components/Internal/SignUp';
import Login from './components/Internal/Login';
import ForgetPassword from './components/Internal/Login/ForgetPassword';
import PendingOrder from './pages/internal/PendingOrder';
import ConfirmedOrder from './pages/internal/ConfirmedOrder';
import ArchivedOrder from './pages/internal/ArchivedOrder';
import HomeReview from './pages/reviews';
import Reviews from './pages/internal/Reviews';
import { RequireAuth } from 'react-auth-kit';

function App() {
  return (
    <>
      <Router>   
        <Routes>
          <Route path='/' exact element={<Home />} />
          <Route path='/reviews' exact element={<HomeReview />}/>
          <Route path='/internal-fa/signin' exact element={<Login />}/>
          <Route path='/internal-fa/register' exact element={<SignUp />}/>
          <Route path='/internal-fa/forgot-password' exat element={<ForgetPassword />}/>
          <Route 
            path='/internal-fa' 
            exact element={
              <RequireAuth loginPath="/internal-fa/signin">
                <HomeInternal />
              </RequireAuth>    
          }/>
          <Route path='/internal-fa/Open' exact element={
            <RequireAuth loginPath="/internal-fa/signin">
              <OpenOrder />
            </RequireAuth>          
          }/>
          <Route path='/internal-fa/Pending' exact element={
            <RequireAuth loginPath="/internal-fa/signin">
              <PendingOrder />
            </RequireAuth>
          }/>
          <Route path='/internal-fa/Confirmed' exact element={
            <RequireAuth loginPath="/internal-fa/signin">
              <ConfirmedOrder />
            </RequireAuth>  
          }/>
          <Route path='/internal-fa/Archived' exact element={
            <RequireAuth loginPath="/internal-fa/signin">
              <ArchivedOrder />
            </RequireAuth>
          }/>
          <Route path='/internal-fa/Reviews' exact element={
             <RequireAuth loginPath="/internal-fa/signin">
              <Reviews />
             </RequireAuth>
          }/>
        </Routes>
      </Router>
    </>
  )
}

export default App;
