import React,{useState, useEffect} from 'react'
import ReviewNav from './reviewNav';
import ReviewSidebar from './ReviewSideBar';
import AddReview from './AddReview/AddReview'
import Reviews from './Reviews'
import Rating from './Rating';
import "bootstrap/dist/css/bootstrap.min.css"
import LoadingModal from '../../components/Modals/LoadingModal'

const HomeReview = ({type}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isAddReviewModalOpened, setIsAddReviewModalOpened] = useState(false)
  const toggle = () => {
    setIsOpen(!isOpen)
  }

  const handleSetIsAddReviewModalOpened = () =>{
    setIsAddReviewModalOpened(!isAddReviewModalOpened)
  }

  const [reviews, setReviews] = useState([])
  const [loadingModal, setLoadingModal] = useState(false)

 
  useEffect(() => {
      setLoadingModal(true)
      loadDataOnlyOnce(); // this will fire only on first render
  }, [type]);
  async function loadDataOnlyOnce(){
      await fetch('https://fabackend.herokuapp.com/reviews')
      .then((response) => response.json())
      .then((data) => {          
          setReviews(data)           
          setLoadingModal(false)
      });
  }

  return (
    <>
      {loadingModal?
        <LoadingModal isOpen={loadingModal}/>
        :
        <div></div>
      }
        <ReviewSidebar 
        isOpen={isOpen} 
        toggle={toggle} 
        handleSetIsAddReviewModalOpened={handleSetIsAddReviewModalOpened}
        isAddReviewModalOpened={isAddReviewModalOpened}
        />
        <ReviewNav 
        toggle={toggle}
        handleSetIsAddReviewModalOpened={handleSetIsAddReviewModalOpened}
        isAddReviewModalOpened={isAddReviewModalOpened}
        />
        <Rating reviews={reviews} id="summary"/>
        <AddReview 
        isOpen = {isAddReviewModalOpened} 
        handleSetIsAddReviewModalOpened={handleSetIsAddReviewModalOpened}
        isAddReviewModalOpened={isAddReviewModalOpened}
        />
        <Reviews reviews={reviews} isConfirmed={true} id="reviews"
        handleSetIsAddReviewModalOpened={handleSetIsAddReviewModalOpened}
        isAddReviewModalOpened={isAddReviewModalOpened}
        />
        
    </>
  )
}

export default HomeReview