import React from 'react'
import { SidebarContainer, Icon, CloseIcon, SideBarLink,SideBtnWrap, SidebarWrapper, SidebarMenu } from './SideBarInternalElements'
import {useSignOut} from 'react-auth-kit'
import {Button} from '../../../components/ButtonElements'
const SideBarInternal = ({isOpen, toggle}) => {
    const signOut = useSignOut();
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
        <Icon onClick={toggle}>
            <CloseIcon />
        </Icon>
        <SidebarWrapper>
            <SidebarMenu>
                <SideBarLink to ="/internal-fa/Open" onClick={toggle}>
                    Open
                </SideBarLink>
                <SideBarLink to ="/internal-fa/Pending" onClick={toggle}>
                    Pending
                </SideBarLink>
                <SideBarLink to ="/internal-fa/Confirmed" onClick={toggle}>
                    Confirmed
                </SideBarLink>
                <SideBarLink to ="/internal-fa/Archived" onClick={toggle}>
                    Archived
                </SideBarLink>
                <SideBarLink to ="/internal-fa/reviews" onClick={toggle}>
                    Reviews
                </SideBarLink>
            </SidebarMenu>
            <SideBtnWrap>
                <Button fontBig primary dark = {true} onClick={signOut}>Log Out</Button>
            </SideBtnWrap>
        </SidebarWrapper>
    </SidebarContainer>
  )
}

export default SideBarInternal