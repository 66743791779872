import React from 'react'
import {
    RatingContainer,
    RatingTopCover,
    RatingTop,
    RatingPicture,
    RatingHeadlineCover,
    RatingHeadline,
    RatingAndReviewsContainer,
    RatingCover,
    RatingLabel,
    //RatingStar,
    RatingFilledStar,
    RatingLinks,
    RecommendCover,
    RecommendPercentage,
    RecommendDesc,
    CategoriesContainer,
    CategoriesCover,
    CategoriesLabelCoverOut,
    CategoriesLabelCoverIn,
    CategoriesLabel,
    CategoriesLabel2,
    CategoriesBar,
    RateStarCover,
    CategoriesBottom
} from './RatingsElements'
const Rating = ({reviews}) => {


    let ratingTotal = 5.0;
    let numberOfReviewsTotal = 0;
    let recommendPercentageTotal = 1;
    let KnowledgableValueTotal = 100;
    let HelpfulValueTotal = 100;
    let TimelyValueTotal = 100;
    let CommunicatingValueTotal = 100;
    reviews = reviews.filter(review=>review.status === "Confirmed")
    reviews.forEach((review)=>{
        ratingTotal+= parseFloat(review.star)
        numberOfReviewsTotal++
        if(review.isRecommended){
            recommendPercentageTotal++
        }   
        KnowledgableValueTotal+= parseFloat(review.knowledgable)
        HelpfulValueTotal+= parseFloat(review.helpful)
        TimelyValueTotal+= parseFloat(review.timely)
        CommunicatingValueTotal+= parseFloat(review.communicating)   
        
    })
    const rating = (ratingTotal/(reviews.length+1)).toFixed(2);
    const recommendPercentage = ((recommendPercentageTotal*100)/(reviews.length+1)).toFixed(0);
    const KnowledgableValue = KnowledgableValueTotal/(reviews.length+1);
    const HelpfulValue = HelpfulValueTotal/(reviews.length+1);
    const TimelyValue = TimelyValueTotal/(reviews.length+1);
    const CommunicatingValue = CommunicatingValueTotal/(reviews.length+1);

  return (
    <RatingContainer id='summary'>
        <RatingTopCover>
            <RatingTop>FA Tutoring</RatingTop>
        </RatingTopCover>
        <RatingPicture/>
        <RatingHeadlineCover>
            <RatingHeadline>Ratings</RatingHeadline>
        </RatingHeadlineCover>
        <RatingAndReviewsContainer>
            <RatingCover>
                <RatingLabel>{rating}</RatingLabel>
                <RateStarCover>
                    <RatingFilledStar/>
                    <RatingFilledStar/>
                    <RatingFilledStar/>
                    <RatingFilledStar/>
                    <RatingFilledStar/>
                </RateStarCover>
                <RatingLinks to="reviews">{numberOfReviewsTotal} Reviews</RatingLinks>
            </RatingCover>
            <RecommendCover>
                <RecommendPercentage>{recommendPercentage}%</RecommendPercentage>
                <RecommendDesc>of customers recommend FA tutoring service</RecommendDesc>
            </RecommendCover>
        </RatingAndReviewsContainer>
        <CategoriesContainer>
            <CategoriesCover>
                <CategoriesLabelCoverOut>
                    <CategoriesLabelCoverIn>
                        <CategoriesLabel>Knowledgable</CategoriesLabel>
                    </CategoriesLabelCoverIn>
                </CategoriesLabelCoverOut>
                <CategoriesBar 
                type="range"
                value= {KnowledgableValue}
                readOnly={true}
                />
                <CategoriesBottom>
                    <CategoriesLabel2>poor</CategoriesLabel2>
                    <CategoriesLabel2>perfect</CategoriesLabel2>
                </CategoriesBottom>
            </CategoriesCover>
            <CategoriesCover>
                <CategoriesLabel>Helpful</CategoriesLabel>
                <CategoriesBar type="range" value= {HelpfulValue} readOnly={true} />
                <CategoriesBottom>
                    <CategoriesLabel2>poor</CategoriesLabel2>
                    <CategoriesLabel2>perfect</CategoriesLabel2>
                </CategoriesBottom>
            </CategoriesCover>
            <CategoriesCover>
                <CategoriesLabel>Timely</CategoriesLabel>
                <CategoriesBar type="range" value= {TimelyValue} readOnly={true}/>
                <CategoriesBottom>
                    <CategoriesLabel2>poor</CategoriesLabel2>
                    <CategoriesLabel2>perfect</CategoriesLabel2>
                </CategoriesBottom>
            </CategoriesCover>
            <CategoriesCover>
                <CategoriesLabel>Communicating</CategoriesLabel>
                <CategoriesBar type="range" value= {CommunicatingValue} readOnly={true} />
                <CategoriesBottom>
                    <CategoriesLabel2>poor</CategoriesLabel2>
                    <CategoriesLabel2>perfect</CategoriesLabel2>
                </CategoriesBottom>
            </CategoriesCover>
        </CategoriesContainer>
    </RatingContainer>
  )
}

export default Rating