import styled from 'styled-components'
import { default as ReactSelect, components} from "react-select";

export const FormCover = styled.div`
    display:grid;
    background-color: #000;
    justify-content: center;
    align-items:center;
    padding-top: 50px;
    padding-bottom: 100px;
    display: none;

    @media screen and (max-width:768px) {
        display: inline;
        display:grid;
        background-color: #000;
        justify-content: center;
        align-items:center;
        padding-top: 50px;
        padding-bottom: 100px;
    }
`

export const Form = styled.form`
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items:center;
    width:720px;
    color: #fff;

    @media screen and (max-width:800px) {
        width: 350px;
    }
`;

export const FormWapper = styled.div`
    display:flex;
    flex-direction:column; 
    width:100%;  
`;

export const FormWapperSide = styled.div`
    display:flex;
    align-items: stretch;
`;

export const LabelWapper = styled.div`
    display:flex;
    flex-direction:flex-start;
    padding-bottom: 5px;
`;

export const LabelWapperCheckBox = styled.div`
    display:flex;
    flex-direction:flex-start;
    width: 400px;
    padding-bottom: 5px;
    @media screen and (max-width: 768px){
        width: 300px;
    }
`;
export const Label = styled.label`
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode','Lucida Grande','Lucida Sans', Arial, sans-serif;
    font-size:16px;
    justify-content:center;
    align-items:center;
    padding-top:10px;
    padding-right:10px;
    color: #fff;
    font-style: italic;
`;

export const LabelLarge = styled.h1`
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode','Lucida Grande','Lucida Sans', Arial, sans-serif;
    font-size:30px;
    justify-content:center;
    align-items:center;
    padding-top:10px;
    padding-right:10px;
    color: #01BF71;
    font-style: oblique;
`;


export const LabelLargeCover = styled.div`
    display: grid;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    border-style: none none solid none;
`

export const FormInput = styled.input`
    padding: 10px 20px;
    border-radius: 4px;
    outline: none;
    border: none;
    font-size: 14px;
    border: 1px solid #ccc;
    margin-bottom:5px;
    resize: vertical;
    color: #000;
    
    &::placeholder{
        color: #aaa;
    }
`;

export const FormOption =styled(components.Option)`
    display: flex;
    justify-content: left;
    align-items: left;

`
export const FormReactSelect = styled(ReactSelect)`
    font-size: 16px;
    margin-bottom: 10px;
    width: 720px;
    color: #000;

    @media screen and (max-width:760px) {
        width: 100%;
    }
    &.Select--multi  {

        .Select-value {
            display: inline-flex;
            align-items: left;
        }
    }

    & .Select-placeholder {
        font-size: smaller;
        color: #aaa;
    }
`
export const FormTextarea = styled.textarea`
    padding: 10px 20px;
    border-radius: 4px;
    outline: none;
    border: none;
    font-size: 16px;
    border: 1px solid #fff;
    height:100px;
    color: #000;
    &::placeholder{
        color: #aaa;
    }
`;

export const FormCheckBoxPackageContainer = styled.div`
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding: 10px 20px;
    justify-content: space-around;
    background-color: #101f10;
`

export const FormCheckBoxPackageWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 20px;
    padding: 10px 10px;
    min-width: 120px;
    background-color: ${({isClicked}) => (isClicked ? '#01BF71': '#fff')};
    color: #000;
`
export const LabelPackageWrapper = styled.div`
    font-size: 30px;
`

export const ButtonWrap = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    width: 90%;
    margin-top: 20px;
`

export const EachButtonWrap = styled.div`
    padding: 0px 20px;
`

export const ExitButtonWrap = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-style: none none solid none;
`

export const FormWapperSlideBar = styled.div`
    display: flex;
    flex-direction: column;
    width: 350px;
    
`

export const FormWapperSlideBarCover = styled.div`
    display: grid;
    grid-auto-columns: minmax(auto, 1fr);
    grid-template-areas: ${({imgStart}) =>(imgStart? `'col2 col1'`: `'col1 col2'`)};
    width: 720px;

    @media screen and (max-width: 768px){
        grid-template-areas: ${({imgStart}) =>(imgStart? `'col1' 'col2'`: `'col1 col1' 'col2 col2'`)};
        width: 350px;
    }
`
export const AddReviewSlideBarCover = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
`
export const AddReviewCheckboxCover = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    @media screen and (max-width: 768px){
        max-width: 150px;
    }
`

export const AddReviewSlideBar = styled.input`
    height: 5px;
    -webkit-appearance: none;
    margin: 10px 0;
    width: 90%;

    &:focus{
        outline: none;
    }

    &::-webkit-slider-runnable-track{
        width: 100%;
        height: 2px;
        box-shadow: 1px 1px 1px #fff;
        background: #fff;
        border-radius: 5px;
        border: 1px solid #fff;
    }

    &::-webkit-slider-thumb{
        box-shadow: 1px 1px 1px #000;
        border: 1px solid #fff;
        height: 20px;
        width: 10px;
        cursor: pointer;
        border-radius: 13px;
        background: #01BF71;
        -webkit-appearance: none;
        margin-top: -9px;
    }

    &:focus::-webkit-slider-runnable-track{
        background: #3071A9;
    }

    &::-moz-range-track{
        width: 100%;
        height: 10px;
        cursor: pointer;
        box-shadow: 1px 1px 1px #000000;
        background: #3071A9;
        border-radius: 5px;
        border: 1px solid #000000;
    }
`

export const AddReviewCheckBox = styled.input`
    width: 20px;
    height: 20px;
`