import React, {useState, useEffect} from 'react'
import { FooterContainer,FooterContact,FooterSubHeading, FooterSubText,Form,FormInput,FormWapper,Label,LabelWapper,FormTextarea,FormOption,FormReactSelect, FormCheckBoxPackageWrapper, FormCheckBoxPackageContainer, LabelPackageWrapper} from './contactElements'
import {Button} from '../ButtonElements'
import { subjectsOption } from "./data.js";
import axios from 'axios';
import LoadingModal from '../Modals/LoadingModal';
import {ImArrowRight, ImArrowLeft} from 'react-icons/im'
import {GiCancel} from 'react-icons/gi'
import {delay} from '../../utils/methods'
const  Contact = ({packages,setPackages}) => {
    const initialInput = {
        name: "",
        email: "",
        phone: "",
        zipCode:"",
        packages: packages,
        subjects: [],
        message:""
    }
    const [input, setInput] = useState(initialInput)
    const [errors, setErrors] = useState({})
    const [success, setSuccess] = useState(false)
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [isSelected, setIsSelected] = useState(null)
    const [loadingModal, setLoadingModal] = useState(false)
    const [isBasicClicked, setIsBasicClicked] =useState(false)
    const [isComprehensiveClicked, setIsComprehensiveClicked] =useState(false)
    const [failed, setFailed] = useState(false)
    const [displayReponse, setDisplayResponse] = useState('')

    useEffect(() => {
        if(packages==='basic'){
            setIsBasicClicked(true)
            setIsComprehensiveClicked(false)
        }else if(packages==='comprehensive'){
            setIsBasicClicked(false)
            setIsComprehensiveClicked(true)
        }
    },[packages]);

    useEffect(() => {
        if(isBasicClicked){
            setPackages('basic')
        } 
        if(isComprehensiveClicked){
            setPackages('comprehensive')
        }
        if(!isBasicClicked && !isComprehensiveClicked){
            setPackages('')
        }
    },[isBasicClicked,isComprehensiveClicked,setPackages]);

    const handleChange = (e) =>{
        const {name, value} = e.target;
        setInput({...input, [name]: value})       
    }
     
    const handleChangeMultiSelect = (selected, e) => {
        const {name} = e
        if(e.action === 'remove-value')
        {
            const {removedValue} = e
            setInput({...input, [name]: input.subjects.filter(item => item !== removedValue.label)}) 
        }else if(e.action === 'select-option'){
            const {option} = e
            setInput({...input, [name]: [...input.subjects,option.label]}) 
        }
        setIsSelected(selected)
    };

    const handlebasicPackageChange = ()=>{
        setIsBasicClicked(!isBasicClicked)
        if(isComprehensiveClicked)
        {
            setIsComprehensiveClicked(!isComprehensiveClicked)
        }
    }

    const handleComprehensivePackageChange = ()=>{
        setIsComprehensiveClicked(!isComprehensiveClicked)       
        if(isBasicClicked)
        {
            setIsBasicClicked(!isBasicClicked)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const newErrors = validation(input,packages)
        setErrors(newErrors)
        if(Object.keys(newErrors).length === 0){
            setLoadingModal(true)
            const contact = {
                name: input.name,
                email: input.email,
                phone: input.phone,
                zipCode: input.zipCode,
                subjects: input.subjects,
                message: input.message,
                packages: packages
            }
            try{
                await axios.post('https://fabackend.herokuapp.com/contacts',contact)
                .then(async()=>{
                    setSuccess(true)
                    setLoadingModal(false)
                    setIsSubmitted(true)
                    setInput(initialInput)
                    setIsSelected(null)
                    setIsBasicClicked(false)
                    setIsComprehensiveClicked(false)
                    setPackages('')
                    await delay(3000)
                    setSuccess(false)
                    setIsSubmitted(false)
                })
            }catch(err){
                setFailed(true)
                setDisplayResponse(err.response.data)
                await delay(3000)
                setFailed(false)
                setDisplayResponse("")
                await delay(2000)
            }
        }
    }

    const validation = (value, packages) =>{
        const validateError = {};
        const regexEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
        const regexPhone = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/
        const regexZipCode = /^\d{5}$/
        if(!value.name){
            validateError.name = "Name is required"
        }
        if(!value.email){
            validateError.email  = "Email is required"
        }else if(!regexEmail.test(value.email)){
            validateError.email  = "This is not a valid email format - Ex: fatutoring@gmail.com"
        }
        if(!value.phone){
            validateError.phone = "Phone number is required"
        }else if(!regexPhone.test(value.phone)){
            validateError.phone  = "This is not a valid phone number format - Ex: 916 111 2222"
        }
        if(!value.zipCode){
            validateError.zipCode = "Zip code is required"
        }else if(!regexZipCode.test(value.zipCode)){
            validateError.zipCode = "Wrong zipcode format - Ex: 95828"   
        }
        if(value.subjects.length === 0){
            validateError.subjects= "At least one subject is required"
        }
        if(!value.message){
            validateError.message= "Message is required"
        }
        if(!packages){
            validateError.packages= "One package is required"
        }
        return validateError
    }  


    const Option = (props) => {
        return (
            <FormOption {...props}>
              <input
                name= "subjects"
                type="checkbox"
                checked={props.isSelected}
                onChange={()=>{}}
              />{""}
              <label>{props.label}</label>
            </FormOption>
        );
      };

    return (
        <>
        {loadingModal?
            <LoadingModal isOpen={loadingModal}/>
            :
            <div></div>
        }
        <FooterContainer id ="contact">
            <FooterContact>
                <FooterSubHeading>
                   Contact
                </FooterSubHeading>
                <FooterSubText>
                    Let's share your information and our team will reach out to you soon
                </FooterSubText>
                {
                    isSubmitted && success && Object.keys(errors).length ===0?
                    <button
                    onClick={()=>setSuccess(false)}
                    className={`alert alert-success`}
                    >
                    <strong>Success!</strong> Your message is sent!
                    </button>  
                    :
                    <div></div>
                   
                }
                {failed?
                    <button
                        onClick={()=>setFailed(false)}
                        className={`alert alert-danger`}
                        >
                        <strong>Failed!</strong> {displayReponse}
                    </button>  
                    :
                    <div></div>
                }
                <Form onSubmit={handleSubmit}>
                    <FormWapper>
                    <LabelWapper><Label>Email</Label></LabelWapper>
                    <FormInput name='email' placeholder="Your Email - Ex: myemail@gmail.com" value={input.email} onChange={handleChange}/>
                    {errors.email?
                    <div className="text-danger  m-2">{errors.email}</div>
                    :
                    <div></div>
                    }
                    </FormWapper>
                    <FormWapper>
                    <LabelWapper><Label>Name</Label></LabelWapper>
                    <FormInput name='name' type= 'text' placeholder="Your Name" value={input.name} onChange={handleChange}/>
                    {errors.name?
                    <div className="text-danger  m-2">{errors.name}</div>
                    :
                    <div></div>
                    }
                    </FormWapper>
                    <FormWapper>
                    <LabelWapper><Label>Phone</Label></LabelWapper>
                    <FormInput name='phone' type= 'text' placeholder="Your Phone Number - Ex: 916 234 5678" value={input.phone} onChange={handleChange}/>
                    {errors.phone?
                    <div className="text-danger  m-2">{errors.phone}</div>
                    :
                    <div></div>
                    }
                    </FormWapper>
                    <FormWapper>
                    <LabelWapper><Label>Zip Code</Label></LabelWapper>
                    <FormInput name='zipCode' type= 'text' placeholder="Your Zip Code - Ex: 95828" value={input.zipCode} onChange={handleChange}/>
                    {errors.zipCode?
                    <div className="text-danger  m-2">{errors.zipCode}</div>
                    :
                    <div></div>
                    }
                    </FormWapper>
                    <FormWapper>
                    <LabelWapper><Label>Package</Label></LabelWapper>
                    <FormCheckBoxPackageContainer>
                        <FormCheckBoxPackageWrapper onClick={handlebasicPackageChange} isClicked={isBasicClicked}>
                            Basic
                        </FormCheckBoxPackageWrapper>
                        <LabelPackageWrapper>
                            {!isBasicClicked?<>
                            {isComprehensiveClicked?
                                <ImArrowRight/>
                                :
                                <GiCancel/>
                            }</>:
                                <ImArrowLeft/>
                            }
                        </LabelPackageWrapper>
                        <FormCheckBoxPackageWrapper  onClick={handleComprehensivePackageChange} isClicked={isComprehensiveClicked}>
                            Comprehensive
                        </FormCheckBoxPackageWrapper>
                    </FormCheckBoxPackageContainer>
                    {errors.packages?
                    <div className="text-danger  m-2">{errors.packages}</div>
                    :
                    <div></div>
                    }
                    </FormWapper>
                    <FormWapper>
                    <LabelWapper><Label>Subjects</Label></LabelWapper>
                    <FormReactSelect
                        name='subjects'
                        options={subjectsOption}
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{
                            Option
                        }}
                        onChange={handleChangeMultiSelect}
                        allowSelectAll={true}
                        value={isSelected}
                    />
                    {errors.subjects?
                    <div className="text-danger  m-2">{errors.subjects}</div>
                    :
                    <div></div>
                    }
                    </FormWapper>
                    <FormWapper>
                    <LabelWapper><Label>Message</Label></LabelWapper>
                    <FormTextarea name='message' type= 'textarea' placeholder="Write Your message..." value={input.message} onChange={handleChange}/>
                    {errors.message?
                    <div className="text-danger  m-2">{errors.message}</div>
                    :
                    <div></div>
                    }
                    </FormWapper>
                    <Button fontBig primary dark = {true}>Contact</Button>
                </Form>
                <FooterSubText>
                   
                </FooterSubText>
            </FooterContact>
           
        </FooterContainer>
        </>
    )
}

export default Contact

