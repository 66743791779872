import React,{useState} from 'react'
import {LoginContainer, 
    Form, 
    FormWapper, 
    FormInput, 
    TopContainer,
    Label, NavBtnLink, LabelLargeCover, LabelLarge, LabelWapper, ButtonWrap, EachButtonWrap,NameWrapper} from './SignUpElements'
import axios from 'axios'
import {Button} from '../../../components/ButtonElements'
import { Navigate } from "react-router-dom";
import {delay} from '../../../utils/methods'
const SignUp = () => {

    const initialInput = {
        firstName: "",
        lastName: "",
        email:"",
        username: "",
        password:"",
        repassword:"",
        birthday: "",
        code:""
    }
    const [input, setInput] = useState(initialInput)
    const [errors, setErrors] = useState({})
    const [success, setSuccess] = useState(false)
    const [failed, setFailed] = useState(false)
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [displayReponse, setDisplayResponse] = useState('')
    const [authenticated, setauthenticated] = useState(false);

    const handleChange = (e) =>{
        const {name, value} = e.target;
        setInput({...input, [name]: value}) 
    }
    const validationGeneratingCode = (value)=>{
        const validateError = {};
        if(!value.email){
            validateError.email = "Email is required"
        }
        return validateError
    }
    const validation = (value) =>{
        const validateError = {};
        const regexDate = /^(0[1-9]|1[012])[-](0[1-9]|[12][0-9]|3[01])[-](19|20)\d\d$/
        if(!value.username){
            validateError.username = "Username is required"
        }
        if(!value.password){
            validateError.password= "Password is required"
        }
        if(!value.repassword){
            validateError.repassword= "ReType-Password is required"
        }
        if(value.repassword){
            if(value.repassword !== value.password)
            validateError.repassword= "passwords do not match"
        }
        if(!value.firstName){
            validateError.firstName = "First Name is required"
        }
        if(!value.lastName){
            validateError.lastName= "Last Name is required"
        }
        if(!value.email){
            validateError.email = "Email is required"
        }
        if(!value.code){
            validateError.code= "Code is required"
        }if(!value.birthday){
            validateError.birthday= "Birthday is required"
        }if(value.birthday){
            if(!regexDate.test(value.birthday)){
                validateError.birthday= "Birthday has wrong format. MM-DD-YYYY"
            }
        }
        return validateError
    }
    const handleGeneratingCode = async (e)=>{
        e.preventDefault()
        const newErrors = validationGeneratingCode(input)
        setErrors(newErrors)
        if(Object.keys(newErrors).length === 0){
            const newCode = {
              email:input.email,
            }
            try{
              await axios.post('https://fabackend.herokuapp.com/emailcode/newcode',newCode)
              .then(()=>{

              })
            }catch(err){
              setFailed(true)
              setDisplayResponse(err.response.data)
              await delay(2000)
              setFailed(false)
              setDisplayResponse("")          
            }
          }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const newErrors = validation(input)
        setErrors(newErrors)
        if(Object.keys(newErrors).length === 0){
          const user = {
            username: input.username,
            password: input.password,
            firstName: input.firstName,
            lastName: input.lastName,
            email:input.email,
            birthday: input.birthday,
            code:input.code
          }
          try{
            await axios.post('https://fabackend.herokuapp.com/users',user)
            .then(async()=>{
              setSuccess(true)
              setFailed(false)
              setIsSubmitted(true)
              setInput(initialInput)
              await delay(3000)
              setSuccess(false)
              setIsSubmitted(false)
              await delay(2000)
              setauthenticated(true)
            })
          }catch(err){
            setFailed(true)
            setDisplayResponse(err.response.data)
            await delay(3000)
            setFailed(false)
            setDisplayResponse("")  
          }
        }
    }
    if (authenticated) {
        return <Navigate replace to="/internal-fa/signin" />;
    } else 
    {
        return (
            <>
                <LoginContainer>
                    <Form onSubmit={handleSubmit}>
                        <LabelLargeCover>
                            <LabelLarge>FA Internal Registration</LabelLarge>
                        </LabelLargeCover>
                        {failed?
                            <button
                                onClick={()=>setFailed(false)}
                                className={`alert alert-danger`}
                                >
                                <strong>Failed!</strong> {displayReponse}
                            </button>  
                            :
                            <div></div>
                            }
                            {
                            isSubmitted && success && Object.keys(errors).length ===0?
                            <button
                            onClick={()=>setSuccess(false)}
                            className={`alert alert-success`}
                            >
                            <strong>Success! </strong> You are about to login 
                            </button>  
                            :
                            <div></div>
                                    
                        }
                        <NameWrapper>
                            <FormWapper>
                            <LabelWapper><Label>First Name</Label></LabelWapper>
                            <FormInput short={true}  name='firstName' placeholder="Your First Name" value={input.firstName} onChange={handleChange}/>
                            {errors.firstName?
                            <div className="text-danger  m-2">{errors.firstName}</div>
                            :
                            <div></div>
                            }
                            </FormWapper>
                            <FormWapper>
                            <LabelWapper><Label>Last Name</Label></LabelWapper>
                            <FormInput short={true} name='lastName' placeholder="Your Last Name" value={input.lastName} onChange={handleChange}/>
                            {errors.lastName?
                            <div className="text-danger  m-2">{errors.lastName}</div>
                            :
                            <div></div>
                            }
                            </FormWapper>
                        </NameWrapper>
                        
                        <FormWapper>
                            <LabelWapper><Label>Username</Label></LabelWapper>
                            <FormInput name='username' placeholder="Your Username" value={input.username} onChange={handleChange}/>
                            {errors.username?
                            <div className="text-danger  m-2">{errors.username}</div>
                            :
                            <div></div>
                            }
                        </FormWapper>
                        <NameWrapper>
                            <FormWapper>
                                <LabelWapper><Label>Password</Label></LabelWapper>
                                <FormInput name='password' placeholder="Your Password" value={input.password} onChange={handleChange}/>
                                {errors.password?
                                <div className="text-danger  m-2">{errors.password}</div>
                                :
                                <div></div>
                                }
                            </FormWapper>
                            <FormWapper>
                                <LabelWapper><Label>ReType-Password</Label></LabelWapper>
                                <FormInput name='repassword' placeholder="Retype Your Password" value={input.repassword} onChange={handleChange}/>
                                {errors.repassword?
                                <div className="text-danger  m-2">{errors.repassword}</div>
                                :
                                <div></div>
                                }
                            </FormWapper>
                        </NameWrapper>
                        <FormWapper>
                            <LabelWapper><Label>Email</Label></LabelWapper>
                            <FormInput name='email' placeholder="Your Email" value={input.email} onChange={handleChange}/>
                            {errors.email?
                            <div className="text-danger  m-2">{errors.email}</div>
                            :
                            <div></div>
                            }
                        </FormWapper>
                        <NameWrapper>
                        <FormWapper>
                            <LabelWapper><Label>Birthday</Label></LabelWapper>
                            <FormInput name='birthday' placeholder="MM-DD-YYYY EX: 12-01-1991" value={input.birthday} onChange={handleChange}/>
                            {errors.birthday?
                            <div className="text-danger  m-2">{errors.birthday}</div>
                            :
                            <div></div>
                            }
                        </FormWapper>
                        <FormWapper>
                            <LabelWapper><Label>Generated Code</Label></LabelWapper>
                            <FormInput name='code' placeholder="Code from your email" value={input.code} onChange={handleChange}/>
                            {errors.code?
                            <div className="text-danger  m-2">{errors.code}</div>
                            :
                            <div></div>
                            }
                        </FormWapper>
                        </NameWrapper>           
                        <ButtonWrap>  
                            <EachButtonWrap>
                                <Button fontBig primary dark = {true}>Register</Button>
                            </EachButtonWrap>                           
                        </ButtonWrap>
                    </Form>
                    <TopContainer>
                        <NavBtnLink to = "/internal-fa/signin">Already register?</NavBtnLink>
                        <EachButtonWrap>
                            <Button onClick={handleGeneratingCode} >Generate New Code</Button>
                        </EachButtonWrap>
                    </TopContainer>                
                </LoginContainer>
            </>         
        )
    }
}

export default SignUp