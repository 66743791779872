import React from 'react';
import { FaBars} from 'react-icons/fa'
import { Nav, NavLogo, NavbarContainer, NavItem, NavMenu, NavLinks, MobileIcon, NavBtn} from './reviewNavElements';
import {SchoolIcon} from '../../../components/Footer/Footer.elements'
import {Button} from '../../../components/ButtonElements'
function ReviewNav ({toggle, handleSetIsAddReviewModalOpened,isAddReviewModalOpened}) {
  const onClick = () =>{
    if(!isAddReviewModalOpened){
      handleSetIsAddReviewModalOpened();
    }
  }
  return (
    <>
      <Nav>
        <NavbarContainer>
            <NavLogo to="/">
              <SchoolIcon/>
              FA
            </NavLogo>
            <MobileIcon onClick={toggle}>
              <FaBars />
            </MobileIcon>
            <NavMenu>
              <NavItem>
                <NavLinks to ="summary"> Summary </NavLinks>
              </NavItem>  
              <NavItem>
                <NavLinks to ="reviews"> Reviews </NavLinks>
              </NavItem>  
            </NavMenu>
            <NavBtn>
              <Button 
              primary={true} 
              onClick={onClick} 
              dark={true}
              >
                Write Us A Review
              </Button>
            </NavBtn>
        </NavbarContainer>
      </Nav>
    </>
  );
}

export default ReviewNav;

