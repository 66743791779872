import styled from 'styled-components';
import {Link as LinkS} from 'react-scroll';

export const Button = styled.button`
    border-radius: 50px;
    box-shadow: ${({primary}) => (primary ? '0px 1px 7px #01BF71': '0px 2px 10px #010606')};
    background: ${({primary}) => (primary ? '#01BF71': '#010606')};
    white-space: nowrap;
    padding: ${({big}) => (big ? '14px 48px': '12px 30px')};
    color: ${({dark}) => (dark ? '#010606': '#fff')};   
    font-size: ${({fontBig}) => (fontBig ? '24px': '17px')};
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    margin-left: 10px;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: ${({primary}) => (primary ? '#E2E2E2': '#01BF71')};
    };
`

export const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
`

export const ButtonLinks = styled(LinkS)`
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;

    &.activeP{
        border-bottom: 3px solid #01bf71;
    }
`;