import React from 'react';
import { FaBars} from 'react-icons/fa'
import { Nav, NavLogo, NavbarContainer, NavItem, NavMenu, NavLinks, MobileIcon, NavBtn, NavBtnLink} from './NavbarElements';
//import logo from '../../photos/logo-green.png'
import {SchoolIcon} from '../Footer/Footer.elements'
function Navbar ({toggle}) {
  return (
    <>
      <Nav>
        <NavbarContainer>
            <NavLogo to="/">
              <SchoolIcon/>
              FA
            </NavLogo>
            <MobileIcon onClick={toggle}>
              <FaBars />
            </MobileIcon>
            <NavMenu>
              <NavItem>
                <NavLinks to ="home"> Home </NavLinks>
              </NavItem>  
              <NavItem>
                <NavLinks to ="about"> About </NavLinks>
              </NavItem>            
              <NavItem>
                <NavLinks to ="services"> Services </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to ="profiles">Tutors' Profile </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to ="contact"> Contact </NavLinks>
              </NavItem>
            </NavMenu>
            <NavBtn>
              <NavBtnLink to = "/reviews">Ratings & Reviews</NavBtnLink>
            </NavBtn>
        </NavbarContainer>
      </Nav>
    </>
  );
}

export default Navbar;

