import React from 'react'
import { SidebarContainer, Icon, CloseIcon, SideBarLink,SideBtnWrap, SidebarWrapper, SidebarRoute, SidebarMenu } from './SideBarElements'

const Sidebar = ({isOpen, toggle}) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
        <Icon onClick={toggle}>
            <CloseIcon />
        </Icon>
        <SidebarWrapper>
            <SidebarMenu>
                <SideBarLink to ="home" onClick={toggle}>
                    Home
                </SideBarLink>
                <SideBarLink to ="about" onClick={toggle}>
                    About
                </SideBarLink>
                <SideBarLink to ="services" onClick={toggle}>
                    Services
                </SideBarLink>
                <SideBarLink to ="profiles" onClick={toggle}>
                    Tutors' Profile 
                </SideBarLink>
                <SideBarLink to ="contact" onClick={toggle}>
                    Contact
                </SideBarLink>
            </SidebarMenu>
            <SideBtnWrap>
                <SidebarRoute to = "/reviews">Ratings & Reviews</SidebarRoute>
            </SideBtnWrap>
        </SidebarWrapper>
    </SidebarContainer>
  )
}

export default Sidebar