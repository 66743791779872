import styled from "styled-components";

export const OrderTableContainer = styled.div`
    align-items:center;
    padding: 10px 40px 20px 0px;
    background-color: #fff;
    margin-top: 100px;
`

export const OrderTableLabel = styled.h1`
    color: #000;
    align-self: flex-end;
    margin: 40px 80px;
`