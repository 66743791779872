export const homeObjOne = {
    id: 'about',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    darkText: false,
    topLine: 'About',
    headLine: "FA Tutoring",
    subHeadLine: "Feeling Awesome For Achieving Fabulous A'sss",
    description: "FA Tutoring is a group of bright and committed individuals who have experience in interacting with both youth and adults. FA Tutoring is offering tutoring services for elementary school, middle school, highschool and college students at home and/or online In the following subjects areas: mathematics, physics, chemistry, biology, and computer science.\n Our tutors are either currently pursuing a bachelor's degree or hold a bachelor's degree in the subjects above. They have extensive teaching experience and have deep understanding of the areas above.",
    buttonLabel: 'Our Services',
    imgStart: false,
    img: require('../../photos/info1.png'),
    alt: 'logo',
    dark: true,
    primary: true,
    to:'services'
}

export const homeObjTwo = {
    id: 'services',
    lightBg: true,
    lightText: false,
    lightTextDesc: true,
    darkText: true,
    topLine: 'Services',
    headLine: "Private Tutoring",
    subHeadLine: "We are committed to go all in to ensure every student needs are met",
    description: "FA tutoring has 1:1 or group tutoring available at nights and weekends. PROMOTION: we will return the fee on the first session if students/parents do not satisfy with our services ",
    buttonLabel: 'Our Tutors',
    imgStart: true,
    img: require('../../photos/info2.png'),
    alt: 'Services',
    dark: true,
    primary: true,
    to:'profiles'
}