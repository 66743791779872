import styled from "styled-components";
import TableCell from '@mui/material/TableCell';

export const CollapsibleTableContainer = styled.div`
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding:  10px 50px 30px 30px;
    margin-top: 20px;
    position: relative;
    z-index: 1;
`

export const AssigneeCover = styled(TableCell)`
    min-width: 100px;
`