import React,{useState} from 'react'
import {LoginContainer, TopContainer, NavBtnLink, Form, FormWapper, FormInput, Label, LabelLargeCover, LabelLarge, LabelWapper, ButtonWrap, EachButtonWrap} from './indexElements'
import axios from 'axios'
import {Button} from '../../../components/ButtonElements'
import {delay} from '../../../utils/methods'
import {useSignIn} from "react-auth-kit"
import { Navigate } from "react-router-dom";
const Login = () => {
    const signIn = useSignIn();
    const initialInput = {
        username: "",
        password:"",
    }

    const [input, setInput] = useState(initialInput)
    const [errors, setErrors] = useState({})
    const [success, setSuccess] = useState(false)
    const [failed, setFailed] = useState(false)
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [displayReponse, setDisplayResponse] = useState('')
    const [authenticated, setAuthenticated] = useState(false)

    const handleChange = (e) =>{
        const {name, value} = e.target;
        setInput({...input, [name]: value}) 
    }
    
    const validation = (value) =>{
        const validateError = {};
        if(!value.username){
            validateError.username = "Username is required"
        }
        if(!value.password){
            validateError.password= "Password is required"
        }
        return validateError
    }
    
    const handleSubmit = async (e) => {
        e.preventDefault()
        const newErrors = validation(input)
        setErrors(newErrors)
        console.log('test')
        if(Object.keys(newErrors).length === 0){
          const user = {
              username: input.username,
              password: input.password,
          }
          try{
            const response = await axios.post('https://fabackend.herokuapp.com/users/login',user)          
            setSuccess(true)
            setFailed(false)
            setIsSubmitted(true)
            setInput(initialInput)
            await delay(500)
            setSuccess(false)
            setIsSubmitted(false)
            await delay(500)
            signIn({
              token: response.data.token,
              expiresIn:3600,
              tokenType: "Bearer",
              authState: {username: response.data.username,email: response.data.email},   
            })
            setAuthenticated(true)
          }catch(err){
            setFailed(true)
            setDisplayResponse(err.response.data)
            await delay(3000)
            setFailed(false)
            setDisplayResponse("")
            setAuthenticated(false)      
          }
        }
    }
  if (authenticated) {
    return <Navigate replace to="/internal-fa" />;
  } 
  else 
  {
    return (
      <LoginContainer>
        <Form onSubmit={handleSubmit}>
            <LabelLargeCover>
                <LabelLarge>FA Internal Login</LabelLarge>
            </LabelLargeCover>
            {failed?
                <button
                    onClick={()=>setFailed(false)}
                    className={`alert alert-danger`}
                    >
                    <strong>Failed!</strong> {displayReponse}
                </button>  
                :
                <div></div>
                }
                {
                isSubmitted && success && Object.keys(errors).length ===0?
                <button
                onClick={()=>setSuccess(false)}
                className={`alert alert-success`}
                >
                <strong>Success! </strong> You are about to login 
                </button>  
                :
                <div></div>
                        
            }
            <FormWapper>
            <LabelWapper><Label>Username</Label></LabelWapper>
            <FormInput name='username' placeholder="Your Username" value={input.username} onChange={handleChange}/>
            {errors.username?
            <div className="text-danger  m-2">{errors.username}</div>
            :
            <div></div>
            }
          </FormWapper>
          <FormWapper>
            <LabelWapper><Label>Password</Label></LabelWapper>
            <FormInput name='password' placeholder="Your Password" value={input.password} onChange={handleChange}/>
            {errors.password?
            <div className="text-danger  m-2">{errors.password}</div>
            :
            <div></div>
            }
          </FormWapper>
          <ButtonWrap>
            <EachButtonWrap>
                <Button fontBig primary dark = {true}>Login</Button>
            </EachButtonWrap>                
          </ButtonWrap>
        </Form>
        <TopContainer>
            <NavBtnLink to = "/internal-fa/register">Did not register yet?</NavBtnLink>
            <NavBtnLink to = "/internal-fa/forgot-password">forget password?</NavBtnLink>
        </TopContainer>
      </LoginContainer>
    )
  }   
}

export default Login