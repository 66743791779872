import React from 'react'
import { SocialMedia, SocialIconLink, SocialIcons, SocialLogo, SocialMediaWrapper, WebsiteRights, SchoolIcon } from './Footer.elements'
import { FaFacebook, FaYoutube,FaInstagram,FaTwitter } from 'react-icons/fa'
const Footer = () => {
    return (
        <>
            <SocialMedia>
                <SocialMediaWrapper>
                    <SocialLogo to='/'>
                        <SchoolIcon/>
                          FA 
                    </SocialLogo>
                    <WebsiteRights> Copy Right 2022</WebsiteRights>
                    <SocialIcons>
                        <SocialIconLink href='https://www.facebook.com/fatutors' target="_blank" aria-label="Facebook">
                            <FaFacebook/>
                        </SocialIconLink>
                        <SocialIconLink href='https://www.instagram.com/fatutoring1/' target="_blank" aria-label="Instagram">
                            <FaInstagram/>
                        </SocialIconLink>
                        <SocialIconLink href='/'  aria-label="Youtube" rel="noopener noreferrer">
                            <FaYoutube/>
                        </SocialIconLink>
                        <SocialIconLink href='/' aria-label="Twitter">
                            <FaTwitter/>
                        </SocialIconLink>
                    </SocialIcons>
                </SocialMediaWrapper>
            </SocialMedia>
        </>
    )
}

export default Footer

