import React,{useState} from 'react'
import NavInternal from '../../components/Internal/NavInternal'
import SideBarInternal from '../../components/Internal/SideBarInternal'
import "bootstrap/dist/css/bootstrap.min.css"
import AllOrder from './AllOrder'

const HomeInternal = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }
  return (
    <>
        <SideBarInternal isOpen={isOpen} toggle={toggle}/>
        <NavInternal toggle={toggle}/>
        <AllOrder/>
    </>
  )
}

export default HomeInternal