import React, {useState} from 'react'
import AddReviewModal from '../../../components/Modals/AddReviewModal/AddReviewModal'
import {
  FormCover, 
  Form, 
  FormWapper, 
  FormWapperSide,
  LabelWapper, 
  Label, 
  FormInput, 
  FormTextarea,
  ButtonWrap,
  EachButtonWrap,
  AddReviewSlideBar,
  AddReviewSlideBarCover,
  FormWapperSlideBar,
  FormWapperSlideBarCover,
  AddReviewCheckBox,
  LabelWapperCheckBox,
  AddReviewCheckboxCover,
  LabelLarge,
  LabelLargeCover
} from './AddReviewElements'
import {Button} from '../../../components/ButtonElements'
import axios from 'axios'
import moment from 'moment'
import {delay} from '../../../utils/methods'

const AddReview = ({isAddReviewModalOpened, handleSetIsAddReviewModalOpened}) => {
  
  const initialInput = {
    name: "",
    review:"",
    knowledgable:100,
    helpful:100,
    timely:100,
    communicating:100,
    isRecommend: true
  }

  const [input, setInput] = useState(initialInput)
  const [errors, setErrors] = useState({})
  const [success, setSuccess] = useState(false)
  const [failed, setFailed] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [displayReponse, setDisplayResponse] = useState('')

  const handleChange = (e) =>{
    const {name, value} = e.target;
    if(name === "isRecommend"){
      setInput({...input, [name]: !input.isRecommend})
    }else{
      setInput({...input, [name]: value}) 
    }
  } 

  const validation = (value) =>{
    const validateError = {};
    if(!value.name){
        validateError.name = "Name is required"
    }
    if(!value.review){
        validateError.review= "Review is required"
    }
    return validateError
  }  

  const handleSubmit = async (e) => {
    e.preventDefault()
    const newErrors = validation(input)
    setErrors(newErrors)
    if(Object.keys(newErrors).length === 0){
      let current = moment(new Date()).format('YYYY-MM-DD:hh:mm:ss')
      const review = {
          name: input.name,
          review: input.review,
          date: current,
          knowledgable: input.knowledgable,
          helpful: input.helpful,
          timely: input.timely,
          communicating: input.communicating,
          star: ((parseFloat(input.communicating)+parseFloat(input.helpful)+parseFloat(input.knowledgable)+parseFloat(input.timely))/80),
          isRecommended: input.isRecommend
      }
      console.log(review)
      try{
        await axios.post('https://fabackend.herokuapp.com/reviews',review)
        .then(async()=>{
          setSuccess(true)
          setFailed(false)
          setIsSubmitted(true)
          setInput(initialInput)
          await delay(3000)
          setSuccess(false) 
          setIsSubmitted(false)
        })
      }catch(err){
        setFailed(true)
        setDisplayResponse(err.response.data)
        await delay(3000)
        setFailed(false)
        setDisplayResponse("")
        await delay(2000)
      }
    }
  }
  return (
    <>
      <AddReviewModal isOpen = {isAddReviewModalOpened} setIsOpen={handleSetIsAddReviewModalOpened}/>
      <FormCover id='addReview'>
        <LabelLargeCover>
          <LabelLarge> Add Review</LabelLarge>
        </LabelLargeCover>
        {failed?
          <button
            onClick={()=>setFailed(false)}
            className={`alert alert-danger`}
            >
            <strong>Failed!</strong> {displayReponse}
          </button>  
          :
          <div></div>
        }
        {
          isSubmitted && success && Object.keys(errors).length ===0?
          <button
          onClick={()=>setSuccess(false)}
          className={`alert alert-success`}
          >
          <strong>Success! </strong> We will review your message soon 
          </button>  
          :
          <div></div>
                
        }
        <Form onSubmit={handleSubmit}>
          <FormWapper>
            <LabelWapper><Label>Name</Label></LabelWapper>
            <FormInput name='name' placeholder="Your Name" value={input.name} onChange={handleChange}/>
            {errors.name?
            <div className="text-danger  m-2">{errors.name}</div>
            :
            <div></div>
            }
          </FormWapper>
          <FormWapperSlideBarCover imgStart={true}>
            <FormWapperSlideBar>
              <LabelWapper><Label>Knowledgable</Label></LabelWapper>
              <AddReviewSlideBarCover>
                <AddReviewSlideBar 
                type='range'
                name='knowledgable'
                value={input.knowledgable}
                onChange={handleChange}
                />
              </AddReviewSlideBarCover>
            </FormWapperSlideBar>
            <FormWapperSlideBar>
              <LabelWapper><Label>Helpful</Label></LabelWapper>
              <AddReviewSlideBarCover>
                <AddReviewSlideBar 
                type='range'
                name='helpful'
                value={input.helpful}
                onChange={handleChange}
                />
              </AddReviewSlideBarCover>
            </FormWapperSlideBar>
            <FormWapperSlideBar>
              <LabelWapper><Label>Timely</Label></LabelWapper>
              <AddReviewSlideBarCover>
                <AddReviewSlideBar 
                  type='range'
                  name='timely'
                  value={input.timely}
                  onChange={handleChange}
                />
              </AddReviewSlideBarCover> 
            </FormWapperSlideBar>
            <FormWapperSlideBar>
              <LabelWapper><Label>Communicating</Label></LabelWapper>
              <AddReviewSlideBarCover>
                <AddReviewSlideBar 
                  type='range'
                  name='communicating'
                  value={input.communicating}
                  onChange={handleChange}
                />
              </AddReviewSlideBarCover> 
            </FormWapperSlideBar>    
          </FormWapperSlideBarCover>                 
          <FormWapper>
          <LabelWapper><Label>Review</Label></LabelWapper>
          <FormTextarea name='review' type= 'textarea' placeholder="Write Your Review..." value={input.review} onChange={handleChange}/>
          {errors.review?
          <div className="text-danger  m-2">{errors.review}</div>
          :
          <div></div>
          }
          </FormWapper>
          <FormWapperSide>
            <LabelWapperCheckBox><Label>Recommend this service?</Label></LabelWapperCheckBox>
            <AddReviewCheckboxCover>
              <AddReviewCheckBox 
                type='checkbox'
                name='isRecommend'
                defaultChecked={true}
                onChange={handleChange}
              />
            </AddReviewCheckboxCover>
          </FormWapperSide>
          <ButtonWrap>
            <EachButtonWrap>
                <Button fontBig primary dark = {true}>Add</Button>
            </EachButtonWrap>                
          </ButtonWrap>
        </Form>
      </FormCover>
    </>
  )
}

export default AddReview