import styled from "styled-components";
import {Link as LinkR} from 'react-router-dom'


export const LoginContainer = styled.div`
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    height: 900px;
`

export const Form = styled.form`
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items:center;
    width:720px;
    color: #fff;
    background-color: #000;
    padding: 20px 40px 20px 40px;
    border-radius: 15px;

    @media screen and (max-width:800px) {
        width: 450px;
    }
    @media screen and (max-width:600px) {
        width: 350px;
    }
`;

export const FormWapper = styled.div`
    display:flex;
    flex-direction:column; 
    width:100%;  
`;

export const FormWapperSide = styled.div`
    display:flex;
    align-items: stretch;
`;

export const LabelWapper = styled.div`
    display:flex;
    flex-direction:flex-start;
    padding-bottom: 5px;
`;

export const LabelWapperCheckBox = styled.div`
    display:flex;
    flex-direction:flex-start;
    width: 400px;
    padding-bottom: 5px;
    @media screen and (max-width: 768px){
        width: 300px;
    }
`;
export const Label = styled.label`
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode','Lucida Grande','Lucida Sans', Arial, sans-serif;
    font-size:16px;
    justify-content:center;
    align-items:center;
    padding-top:10px;
    padding-right:10px;
    color: #fff;
    font-style: italic;
`;

export const LabelLarge = styled.h1`
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode','Lucida Grande','Lucida Sans', Arial, sans-serif;
    font-size:30px;
    justify-content:center;
    align-items:center;
    padding-top:10px;
    padding-right:10px;
    color: #01BF71;
    font-style: oblique;
`;


export const LabelLargeCover = styled.div`
    display: grid;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    border-style: none none solid none;
`

export const FormInput = styled.input`
    padding: 10px 20px;
    border-radius: 4px;
    outline: none;
    border: none;
    font-size: 14px;
    border: 1px solid #ccc;
    margin-bottom:5px;
    resize: vertical;
    color: #000;
    
    &::placeholder{
        color: #aaa;
    }
`;

export const ButtonWrap = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    width: 90%;
    margin-top: 20px;
`

export const EachButtonWrap = styled.div`
    padding: 0px 20px;
`

export const NavBtnLink = styled(LinkR)`
    border-radius: 100px;
    background: #c4f5d1;
    white-space: nowrap;
    padding: 10px 22px;
    margin-left:10px;
    color: #000;
    font-size: 18px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover{
        transition: all 0.2s ease-in-out;
        background: #fff;
        color: #01BF71;
    }
    @media screen and (max-width:600px) {
        margin-top:10px;
    }
`

export const TopContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    @media screen and (max-width:600px) {
        display: grid;
    }
`