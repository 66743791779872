import React from 'react'
import { SidebarContainer, Icon, CloseIcon, ButtonWrap, SideBarLink, SideBarButtonLink,SideBtnWrap, SidebarWrapper, SidebarMenu } from './ReviewSideBarElements'
import {Button} from '../../../components/ButtonElements'
const ReviewSidebar = ({isOpen, toggle, handleSetIsAddReviewModalOpened, isAddReviewModalOpened}) => {
    const onClick = () =>{
        if(!isAddReviewModalOpened){
            handleSetIsAddReviewModalOpened();
        }
    }
    return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
        <Icon onClick={toggle}>
            <CloseIcon />
        </Icon>
        <SidebarWrapper>
            <SidebarMenu>
                <SideBarLink to ="summary" onClick={toggle}>
                    Summary
                </SideBarLink>
                <SideBarLink to ="reviews" onClick={toggle}>
                    Reviews
                </SideBarLink>
            </SidebarMenu>
            <SideBtnWrap>
                <ButtonWrap>
                    <Button
                    primary={true} 
                    onClick={onClick} 
                    dark={true}
                    >
                        Write Us A Review
                    </Button>
                </ButtonWrap>
                <SideBarButtonLink to ="addReview" onClick={toggle}>
                    Write Us A Review
                </SideBarButtonLink>
            </SideBtnWrap>
        </SidebarWrapper>
    </SidebarContainer>
  )
}

export default ReviewSidebar