import React from 'react'
import {ProfilesContainer, ProfilesH1, ProfilesWrapper, ProfilesCard, ProfilesIcon, ProfilesH2, ProfilesP} from './ProfilesElements'
import { SocialIcon } from '../Footer/Footer.elements'
import Nhat from '../../photos/nhat.jpg'
import Trinh from '../../photos/bap.jpg'
import Thao from '../../photos/Thao.jpg'
import Diem from '../../photos/Diem.jpg'
import Dang from '../../photos/Dang.jpg'
import Roselyn from '../../photos/Rose-final.jpg'
import Ngoc from '../../photos/Ngoc.jpg'
const Profiles = () => {
  return (
    <>
        <ProfilesContainer id="profiles">
            <ProfilesH1>
                <SocialIcon/> 
                OUR TUTORS
            </ProfilesH1>
            <ProfilesWrapper>
                <ProfilesCard>
                    <ProfilesIcon src = {Nhat} />
                    <ProfilesH2>Nhật Đoàn</ProfilesH2>
                    <ProfilesP>
                        I'm Nhat Doan! I graduated from Sac State with a Bachelor's Degree in Computer Science in May of 2021. I have been a tutor in math and computer science for more than 6 years. It is great to see students achieve their goals, and that is my goal to ensure that to happen.
                    </ProfilesP>
                </ProfilesCard>
                <ProfilesCard>
                    <ProfilesIcon src = {Ngoc} />
                    <ProfilesH2>Ngọc Nguyễn</ProfilesH2>
                    <ProfilesP>
                        Hello, my name is Ngọc Nguyễn. I am currently a fourth year UC Davis student, majoring in Biochemistry and Molecular Biology. I have been a tutor in biology, chemistry, but mostly in math. I enjoy teaching and incorporating different strategies to make learning fun and meaningful.
                    </ProfilesP>
                </ProfilesCard>
                <ProfilesCard>
                    <ProfilesIcon src = {Diem} />
                    <ProfilesH2>Diễm Nguyễn</ProfilesH2>
                    <ProfilesP>
                        Hello, I am Kieu Diễm Nguyễn. I am majoring in Biological Sciences at UC Davis. I have been a tutor in math and biology for three years at Cosumnes River College. As a tutor, I saw myself as a patient, accountable, and understanding person. I can provide students with the best learning environment 
                    </ProfilesP>
                </ProfilesCard>
                <ProfilesCard>
                    <ProfilesIcon src = {Thao} />
                    <ProfilesH2>Thảo Nguyễn</ProfilesH2>
                    <ProfilesP>
                        Hello, I’m Thảo Nguyễn. I’m currently in my fourth year at Sacramento State majoring in Health Science and minor in Nutrition and Food. Our goal is to help youths learn by imparting knowledge to them and create activities for youths to have fun.
                    </ProfilesP>
                </ProfilesCard>
                <ProfilesCard>
                    <ProfilesIcon src = {Trinh} />
                    <ProfilesH2>Trinh Nguyễn</ProfilesH2>
                    <ProfilesP>
                        Hello, My name is Trinh Nguyễn. I graduated from California State University, Sacramento with a Bachelor's Degree in Public Health in May 2021.
                    </ProfilesP>
                </ProfilesCard>
                <ProfilesCard>
                    <ProfilesIcon src = {Dang} />
                    <ProfilesH2>Đăng Hà</ProfilesH2>
                    <ProfilesP>
                        Hello, My name is Đăng Hà. I am currently a 4th year student at University of California, Davis, majoring in biological sciences
                    </ProfilesP>
                </ProfilesCard>
                <ProfilesCard>
                    <ProfilesIcon src = {Roselyn} />
                    <ProfilesH2>Roselyn Nguyen</ProfilesH2>
                    <ProfilesP>
                        My name is Roselyn Nguyễn. I graduated from the University of the Pacific with a Bachelor’s in Biochemistry and have an Associate’s in Pre-Health Occupation.
                    </ProfilesP>
                </ProfilesCard>
            </ProfilesWrapper>
        </ProfilesContainer>
    </>
  )
}

export default Profiles