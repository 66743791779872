import React from 'react'
import { useState, useEffect } from 'react'
import { InfinitySpin} from 'react-loader-spinner'
import { View } from "react-native"
import Modal from 'react-modal';

const customStyles = {
    content: {
      top: '50%',
      left: '47.5%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderStyle: 'none',
      background: 'transparent'
    },
  };


const LoadingModal = ({isOpen}) => {
    const [modalIsOpen, setIsOpen] = useState(false);

    useEffect(() => {
        setIsOpen(isOpen)
    }, [isOpen]);

  return (
    <> {modalIsOpen?
        <Modal
            isOpen={modalIsOpen}
            style={customStyles}
            contentLabel="Loading"
            ariaHideApp={false}
        >
            <View style={{ flex: 1 }}>
                <InfinitySpin
                height="160"
                width="160"
                radius="20"
                color="green"
                ariaLabel="loading"
                wrapperStyle
                wrapperClass
                />
            </View>
        </Modal>
        :
        <div></div>
    }</>  
  )
}

export default LoadingModal