import styled from 'styled-components'
import {Link} from 'react-router-dom'
import { SiOpsgenie } from 'react-icons/si';
import { TbSchool} from 'react-icons/tb'

export const FooterContainer = styled.div`
    background-color: #101522;
    padding: 4rem 0 2rem 2;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const FooterContact = styled.section`
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
    text-align:center;
    margin-bottom: 24px;
    padding: 24px;
    color: #fff;

`;

export const FooterSubHeading = styled.p`
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode','Lucida Grande','Lucida Sans', Arial, sans-serif;
    margin-bottom: 24px;
    font-size: 40px;
`;

export const FooterSubText = styled.p`
    margin-bottom:24px;
    font-size:20px;
`;

export const Form = styled.form`
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items:center;
    width:720px;
    
    @media screen and (max-width:760px) {
        width: 100%;
    }
`;

export const FormWapper = styled.div`
    display:flex;
    flex-direction:column; 
    width:100%;
`;
export const LabelWapper = styled.div`
    display:flex;
    flex-direction:flex-start;
    padding-bottom: 5px;
`;
export const Label = styled.label`
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode','Lucida Grande','Lucida Sans', Arial, sans-serif;
    font-size:22px;
    justify-content:center;
    align-items:center;
    padding-top:10px;
    padding-right:10px;
`;
export const FormInput = styled.input`
    padding: 10px 20px;
    border-radius: 4px;
    outline: none;
    border: none;
    font-size: 16px;
    border: 1px solid #ccc;
    margin-bottom:5px;
    resize: vertical;
    
    &::placeholder{
        color: #aaa;
    }
`;

export const FormTextarea = styled.textarea`
    padding: 10px 20px;
    border-radius: 4px;
    outline: none;
    border: none;
    font-size: 16px;
    border: 1px solid #fff;
    margin-bottom:10px;
    height:100px;
    &::placeholder{
        color: #aaa;
    }
`;
export const Skill = styled.div`
    display:flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
`;

export const SkillTitle = styled.h1`
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode','Lucida Grande','Lucida Sans', Arial, sans-serif;
    font-size: 36px;
    color:#fff;
`

export const FooterLinkContainer = styled.div`

    width:100%;
    max-width: 1000px;
    display:flex;
    justify-content:center;

    @media screen and (max-width:820px){
        padding-top:32px;
    }
`;

export const FooterLinkWrapper = styled.div`
    display:flex;

    @media screen and (max-width:820px){
        flex-direction:column;
    }
`;

export const FooterLink = styled(Link)`
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode','Lucida Grande','Lucida Sans', Arial, sans-serif;
    color: #fff;
    text-decoration:none;
    margin-bottom: 0.5rem;
    font-size:14px;

    &:hover{
        color: #0467fb;
        transition: 0.3s ease-out;
    }
`;

export const FooterLinkItems = styled.div`
    display:flex;
    flex-direction:column;
    align-items: flex-start;
    margin: 16px;
    text-align: left;
    width: 160px;
    box-sizing: border-box;
    color: #fff;

    @media screen and (max-width:420px){
        margin: 0;
        padding: 10px;
        width:100%;
    }
`;

export const FooterLinkTitle = styled.h2`
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode','Lucida Grande','Lucida Sans', Arial, sans-serif;
    margin-bottom:16px;
    font-size:24px;
`;

export const SocialMedia = styled.section`
    width: 100%;
    background-color: #fff;
`;

export const SocialMediaWrapper = styled.div`
    display:flex;
    flex-direction:row;
    justify-content: space-between;
    align-items:center;
    width: 90%;
    height: 20px;
    max-width: 1000px;
    margin: 40px auto 0 auto;
    padding-left: 160px;
    @media screen and (max-width: 820px){
        flex-direction: column;
        padding: 0;
    }
`;

export const SocialLogo = styled(Link)`
    color: #000;
    justify-content: start;
    cursor: pointer;
    text-decoration:none;
    font-size: 2.2rem;
    display:flex;
    align-items:center;
    margin-bottom: 16px;
`;

export const SocialIcon = styled(SiOpsgenie)`
    margin-right:10px;
`;

export const SchoolIcon = styled(TbSchool)`
    margin-right:10px;
`;

export const WebsiteRights= styled.small`
    color:#000;
    margin-bottom: 16px;
    font-size: 16px;
`;

export const SocialIcons = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    width: 240px;
    padding-bottom: 20px;
    @media screen and (max-width: 820px){
        margin-bottom: 30px;
    }
`;

export const SocialIconLink = styled.a`
    color: #000;
    font-size: 28px;
    padding:15px;
    
    @media screen and (max-width: 820px){
        padding:5px;
    }
`;