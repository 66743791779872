import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {AuthProvider} from 'react-auth-kit'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <>
    <AuthProvider
        authType={"cookie"}
        authName={"_auth"}
        cookieDomain={window.location.hostname}
        cookieSecure={false}
    >
        <App id = "FaTutoring App" />
    </AuthProvider>
    </>
);

