import styled from "styled-components";
import proflieBackground from "../../photos/profile-background.jpg";
export const ProfilesContainer = styled.div`
    min-height: 1130px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #010606;
    padding-top: 150px;
    padding-bottom: 100px;

    @media screen and (max-width: 768px){
        height: auto;
    }

    @media screen and (max-width: 480px){
        height: auto;
    }
`

export const ProfilesWrapper = styled.div`
    max-width: 1000px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    grid-gap: 50px;
    margin-right: 180px;

    @media screen and (max-width: 1350px){
        grid-template-columns: 1fr 1fr;
        grid-gap: 35px;
        margin-right: 30px;
    }

    @media screen and (max-width: 768px){
        grid-template-columns: 1fr;
        padding: 0 20px;
        grid-gap: 35px;
        margin-right: 0px;
    }
`


export const ProfilesCard = styled.div`
    background: #fff;
    background-image: url(${proflieBackground});
    box-shadow: 0px 2px 10px #E3E3E3;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
    max-height: 500px;
    padding: 30px;
    transition: all 0.2s ease-in-out;
    min-width: 370px;
    min-height: 440px;

    @media screen and (max-width: 1350px){
        min-width: 250px;
        max-width: 370px
    }

    @media screen and (max-width: 768px){
        min-width: 250px;
        max-width: 370px;
    }

    @media screen and (max-width: 480px){
        min-width: 170px;
        max-width: 320px;
        min-height: 520px;
    }

    &:hover{
        transform: scale(1.1);
        transition: all 0.2s ease-in-out;
        cursor: pointer;
    }
    

`

export const ProfilesIcon = styled.img`
    height: 220px;
    width: 160px;
    margin-bottom: 10px;  
`

export const ProfilesH1 = styled.h1`
    font-size: 2.2rem;
    color: #000;
    margin-bottom: 80px;
    background-color: #fff;
    padding: 10px 25px;
    border-radius: 20px;
    box-shadow: 2px 2px 10px #fff;

    @media screen and (max-width: 480px){
        font-size: 1.9rem;
    }
`

export const ProfilesH2 = styled.h2`
    margin-top:10px;
    font-size: 1.7rem;
    margin-bottom: 7px;
    color: #01BF71;
`

export const ProfilesP = styled.p`
    font-size: 1rem;
    text-align: center;
    font-weight: 550;
    min-width: 350px;

    @media screen and (max-width: 768px){
        width: 350px;
    }

    @media screen and (max-width: 480px){
        font-size: 0.9rem;
        min-width: 170px;
        max-width: 250px;
    }
`