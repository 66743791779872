import React, {useState} from 'react'
import Modal from 'react-modal'
import axios from 'axios'
import moment from 'moment'
import {
  FormCover, 
  Form, 
  FormWapper, 
  FormWapperSide,
  LabelWapper, 
  Label, 
  FormInput, 
  FormTextarea,
  ButtonWrap,
  ExitButtonWrap,
  LabelLarge,
  EachButtonWrap,
  AddReviewSlideBar,
  AddReviewSlideBarCover,
  FormWapperSlideBar,
  FormWapperSlideBarCover,
  AddReviewCheckBox,
  LabelWapperCheckBox,
  AddReviewCheckboxCover
} from './AddReviewModalElements'
import {Button} from '../../ButtonElements'
import LoadingModal from '../LoadingModal'
import {delay} from '../../../utils/methods'
const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '50%',
      transform: 'translate(-50%, -50%)',
      borderStyle: 'solid',
      background: 'transparent',
      borderRadius: '15px',
      backgroundColor: '#000',
    },
  };


const AddReviewModal = ({isOpen, setIsOpen}) => {

  const initialInput = {
    name: "",
    review:"",
    knowledgable:100,
    helpful:100,
    timely:100,
    communicating:100,
    isRecommend: true
  }

  const [input, setInput] = useState(initialInput)
  const [errors, setErrors] = useState({})
  const [success, setSuccess] = useState(false)
  const [failed, setFailed] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [loadingModal, setLoadingModal] = useState(false)
  const [displayReponse, setDisplayResponse] = useState('')

  const handleChange = (e) =>{
    const {name, value} = e.target;
    if(name === "isRecommend"){
      setInput({...input, [name]: !input.isRecommend})
    }else{
      setInput({...input, [name]: value}) 
    }
  }

  function afterOpenModal() {
      // references are now sync'd and can be accessed.
      document.body.style.overflow = 'hidden';
  }
  
  function closeModal() {
      setIsOpen(false);
      setErrors({});
      setInput(initialInput);
      setSuccess(false)
      setFailed(false)
      document.body.style.overflow = 'unset';
  }

  const validation = (value) =>{
    const validateError = {};
    if(!value.name){
        validateError.name = "Name is required"
    }
    if(!value.review){
        validateError.review= "Review is required"
    }
    return validateError
  }  

  const handleSubmit = async (e) => {
    e.preventDefault()
    const newErrors = validation(input)
    setErrors(newErrors)
    if(Object.keys(newErrors).length === 0){
      setLoadingModal(true)
      let current = moment(new Date()).format('YYYY-MM-DD:hh:mm:ss')
      const review = {
          name: input.name,
          review: input.review,
          date: current,
          knowledgable: input.knowledgable,
          helpful: input.helpful,
          timely: input.timely,
          communicating: input.communicating,
          star: ((parseFloat(input.communicating)+parseFloat(input.helpful)+parseFloat(input.knowledgable)+parseFloat(input.timely))/80),
          isRecommended: input.isRecommend
      }
      console.log(review)
      try{
        await axios.post('https://fabackend.herokuapp.com/reviews',review)
        .then(async()=>{
          setLoadingModal(false)
          setSuccess(true)
          setFailed(false)
          setIsSubmitted(true)
          setInput(initialInput)
          await delay(2000)
          setSuccess(false)
          setIsSubmitted(false)
          await delay(1000) 
          closeModal()
        })
      }catch(err){
        setLoadingModal(false)
        setFailed(true)
        setDisplayResponse(err.response.data)
        await delay(3000)
        setFailed(false)
        setDisplayResponse("")     
      }
    }
}

  return (
    <> 
    {loadingModal?
      <LoadingModal isOpen={loadingModal}/>
      :
      <div></div>
    }
    {isOpen?
      <div>
        <Modal
          isOpen={isOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Add Review"
          ariaHideApp={false}
          shouldCloseOnOverlayClick={false}
        >
          <FormCover>
            {failed?
              <button
                onClick={()=>setFailed(false)}
                className={`alert alert-danger`}
                >
                <strong>Failed!</strong> {displayReponse}
              </button>  
              :
              <div></div>
            }
            {
              isSubmitted && success && Object.keys(errors).length ===0?
              <button
              onClick={()=>setSuccess(false)}
              className={`alert alert-success`}
              >
              <strong>Success!</strong> Your message is sent!
              </button>  
              :
              <div></div>
                   
            }
            <ExitButtonWrap>
              <LabelWapper><LabelLarge>Add Review</LabelLarge></LabelWapper>
              <Button onClick={closeModal}>X</Button>
            </ExitButtonWrap>
            <Form onSubmit={handleSubmit}>
              <FormWapper>
                <LabelWapper><Label>Name</Label></LabelWapper>
                <FormInput name='name' placeholder="Your Name" value={input.name} onChange={handleChange}/>
                {errors.name?
                <div className="text-danger  m-2">{errors.name}</div>
                :
                <div></div>
                }
              </FormWapper>
              <FormWapperSlideBarCover imgStart={true}>
                <FormWapperSlideBar>
                  <LabelWapper><Label>Knowledgable</Label></LabelWapper>
                  <AddReviewSlideBarCover>
                    <AddReviewSlideBar 
                    type='range'
                    name='knowledgable'
                    value={input.knowledgable}
                    onChange={handleChange}
                    />
                  </AddReviewSlideBarCover>
                </FormWapperSlideBar>
                <FormWapperSlideBar>
                  <LabelWapper><Label>Helpful</Label></LabelWapper>
                  <AddReviewSlideBarCover>
                    <AddReviewSlideBar 
                    type='range'
                    name='helpful'
                    value={input.helpful}
                    onChange={handleChange}
                    />
                  </AddReviewSlideBarCover>
                </FormWapperSlideBar>
                <FormWapperSlideBar>
                  <LabelWapper><Label>Timely</Label></LabelWapper>
                  <AddReviewSlideBarCover>
                    <AddReviewSlideBar 
                      type='range'
                      name='timely'
                      value={input.timely}
                      onChange={handleChange}
                    />
                  </AddReviewSlideBarCover> 
                </FormWapperSlideBar>
                <FormWapperSlideBar>
                  <LabelWapper><Label>Communicating</Label></LabelWapper>
                  <AddReviewSlideBarCover>
                    <AddReviewSlideBar 
                      type='range'
                      name='communicating'
                      value={input.communicating}
                      onChange={handleChange}
                    />
                  </AddReviewSlideBarCover> 
                </FormWapperSlideBar>    
              </FormWapperSlideBarCover>                 
              <FormWapper>
              <LabelWapper><Label>Review</Label></LabelWapper>
              <FormTextarea name='review' type= 'textarea' placeholder="Write Your Review..." value={input.review} onChange={handleChange}/>
              {errors.review?
              <div className="text-danger  m-2">{errors.review}</div>
              :
              <div></div>
              }
              </FormWapper>
              <FormWapperSide>
                <LabelWapperCheckBox><Label>Recommend this service?</Label></LabelWapperCheckBox>
                <AddReviewCheckboxCover>
                  <AddReviewCheckBox 
                    type='checkbox'
                    name='isRecommend'
                    defaultChecked={true}
                    onChange={handleChange}
                  />
                </AddReviewCheckboxCover>
              </FormWapperSide>
              <ButtonWrap>
                <EachButtonWrap>
                    <Button fontBig primary dark = {true}>Add</Button>
                </EachButtonWrap>                
                <Button onClick={closeModal} fontBig primary dark = {true}>Cancel</Button>
              </ButtonWrap>
            </Form>
          </FormCover>
          
        </Modal>
      </div>
      
        :
        <div></div>
    }</>  
  )
}

export default AddReviewModal