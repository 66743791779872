import React, {useState} from 'react'
import { Button ,ButtonLinks} from '../ButtonElements'
import {InforContainer, InfoWrapper, InfoRow, InfoColumn1, InfoColumn2, TextWrapper, TopLine, Heading, SubHeading, SubTitle, BtnWrap, ImgWrap, Img} from './InfoElements'
import {ArrowForward, ArrowRight} from '../HeroSection/HeroElements'
const InfoSection = ({lightBg, id, imgStart, topLine, lightText, lightTextDesc, darkText, description, buttonLabel, img, alt, headLine, subHeadLine, primary, dark, dark2, to}) => {
    const [hover, setHover] = useState(false)

    const onHover = () =>{
        setHover(!hover)
    }
  
    return (
    <>
        <InforContainer lightBg={lightBg} id={id}>
            <InfoWrapper>
                <InfoRow imgStart ={imgStart}>
                    <InfoColumn1>
                        <TextWrapper>
                            <TopLine>{topLine}</TopLine>
                            <Heading lightText = {lightText}>{headLine}</Heading>
                            <SubHeading lightTextDesc = {lightTextDesc}>{subHeadLine}</SubHeading>
                            <SubTitle darkText = {darkText}>{description}</SubTitle>
                            <BtnWrap>
                                <ButtonLinks to={to}>
                                    <Button
                                    smooth= {true}
                                    duration={500}
                                    spy= {true}
                                    exact="true"
                                    offset={-80}
                                    primary={primary? 1: 0}
                                    dark = {dark? 1: 0}
                                    dark2 = {dark2? 1: 0}
                                    onMouseEnter={onHover} 
                                    onMouseLeave={onHover}
                                    fontBig ="true"
                                    > {buttonLabel} {hover ? <ArrowForward /> :<ArrowRight/>}</Button>
                                </ButtonLinks>
                            </BtnWrap>
                        </TextWrapper>
                    </InfoColumn1>
                    <InfoColumn2>
                        <ImgWrap>
                            <Img src={img} alt= {alt}/>
                        </ImgWrap>
                    </InfoColumn2>
                </InfoRow>
            </InfoWrapper>
        </InforContainer>
    </>
  )
}

export default InfoSection