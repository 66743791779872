import * as React from 'react';
import { useEffect,useState } from 'react'
import LoadingModal from '../../../Modals/LoadingModal'
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {CollapsibleTableContainer} from './ColapsibleTableElements'
import {Button, ButtonWrapper} from '../../../ButtonElements'
import axios from 'axios'

function Row(props) {
  const { row, isAdjustable} = props;
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isClosed, setIsClosed] = useState(false);

  const handleApproveReview = (id) =>{
    updateOrder(id)
  }

  const handleDisapproveReview = (id) =>{
    disapproveRewview(id)
  }
  
  const disapproveRewview = async (id)=>{
    const review = {
        status:"Close"
    }
    try{
      await axios.put('https://fabackend.herokuapp.com/reviews/update-status/'+id,review)
      .then(()=>{
        setIsClosed(true);
      })
    }catch(err){
      throw new Error('Unable to send request.'); 
    }
  }

  const updateOrder = async (id)=>{
    const review = {
        status:"Confirmed"
    }
    try{
      await axios.put('https://fabackend.herokuapp.com/reviews/update-status/'+id,review)
      .then(()=>{
        setSuccess(true)
        setIsSubmitted(true)
      })
    }catch(err){
      throw new Error('Unable to send request.'); 
    }
  }

  return (
    <React.Fragment>
      <>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {row.name}
          </TableCell>
          <TableCell align="center">{row.knowledgable}</TableCell>
          <TableCell align="center">{row.helpful}</TableCell>
          <TableCell align="center">{row.timely}</TableCell>
          <TableCell align="center">{row.communicating}</TableCell>
          <TableCell align="center">{row.star}</TableCell>
          <TableCell align="center">{row.date}</TableCell>
          {isAdjustable && row.status === "Pending" && !success && !isSubmitted && !isClosed?
            <TableCell align="center">
              <ButtonWrapper>
                <Button onClick={() => handleApproveReview(row._id)}>Approve</Button>
                <Button onClick={() => handleDisapproveReview(row._id)}>Don't Approve</Button>
              </ButtonWrapper>
            </TableCell>
            :
            <TableCell align="center">
              <ButtonWrapper>
                <h4>Approved</h4>
              </ButtonWrapper>
            </TableCell>
          }
          {row.status === "Close"?
            <TableCell align="center">
              <ButtonWrapper>
                <h4>Closed</h4>
              </ButtonWrapper>
            </TableCell>
            :
            <></>
          }
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={15}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  Details
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Message</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        {row.review}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    review: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default function CollapsibleTableReview({type,isAdjustable,isOpen, isConfirmed, isPending, isClosed}) {
    const [reviews, setReviews] = useState([])
    const [loadingModal, setLoadingModal] = useState(false)

   
    useEffect(() => {
        setLoadingModal(true)
        loadDataOnlyOnce(); // this will fire only on first render
    }, [type]);
    async function loadDataOnlyOnce(){
        await fetch('https://fabackend.herokuapp.com/reviews')
        .then((response) => response.json())
        .then((data) => {
            if(isOpen){
              data = data.filter(order=>order.status === "Open")
            }
            else if(isPending){
              data = data.filter(order=>order.status === "Pending")
            }
            else if(isConfirmed){
              data = data.filter(order=>order.status === "Confirmed")
            }
            else if(isClosed){
              data = data.filter(order=>order.status === "Close")
            }   
            data = data.sort((a, b) => {
              let fa = a.status.toLowerCase(),
                  fb = b.status.toLowerCase();

              if (fa < fb) {
                  return 1;
              }
              if (fa > fb) {
                  return -1;
              }
              return 0;
            });       
            setReviews(data)           
            setLoadingModal(false)
        });
    }
  return (
    <CollapsibleTableContainer>
        {loadingModal?
            <LoadingModal isOpen={loadingModal}/>
            :
            <div></div>
        }
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
              <TableHead>
                  <TableRow>
                      <TableCell />
                      <TableCell>Name</TableCell>
                      <TableCell align="center">Knowledgable</TableCell>
                      <TableCell align="center">Helpful</TableCell>
                      <TableCell align="center">Timely</TableCell>
                      <TableCell align="center">Communicating</TableCell>
                      <TableCell align="center">Star</TableCell>
                      <TableCell align="center">Date</TableCell>
                      {isAdjustable?
                      <TableCell align="center">Action</TableCell>:
                      <></>
                      }
                  </TableRow>
              </TableHead>
              <TableBody>
                  {reviews.map((review) => (
                      <Row key={review._id} row={review} isAdjustable={isAdjustable} isOpen={isOpen} isConfirmed={isConfirmed} isPending={isPending} isClosed={isClosed}/>
                  ))}
              </TableBody>
          </Table>
        </TableContainer>
    </CollapsibleTableContainer>  
  );
}