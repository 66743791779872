import * as React from 'react';
import { useEffect,useState } from 'react'
import LoadingModal from '../../../Modals/LoadingModal'
import Modal from 'react-modal'
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {CollapsibleTableContainer, AssigneeCover} from './ColapsibleTableElements'
import {Button, ButtonWrapper} from '../../../ButtonElements'
import axios from 'axios'
import {delay} from '../../../../utils/methods'
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

import {
  FormCover, 
  Form, 
  FormWapper, 
  LabelWapper, 
  Label, 
  ButtonWrap,
  ExitButtonWrap,
  LabelLarge,
  EachButtonWrap,
} from '../../../Modals/AddReviewModal/AddReviewModalElements'

function Row(props) {
  const { row, isAdjustable, isOpen, isConfirmed, isPending, users } = props;
  const [open, setOpen] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [isPushed, setIsPushed] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [assignee, setAssignee] = useState("")
  const [errorAssignee, setErrorAssignee] = useState(false)

  const options =  users.map((user)=> {
    return(user.name.firstName )
  }).filter(user=>user !== "admin")

  const handleDeleteOrder = (id) =>{
    setIsDeleting(true)
    //deleteOrder(id)
  }

  const validateAssignee = (value)=>{
    return value === ""
  }

  const handleUpdateStatusPending = (id) =>{
    if(!validateAssignee(assignee)){
      UpdateStatus(id, "Pending", assignee)
    }else{
      setErrorAssignee(true)
    }
  }

  const handleUpdateStatusPendingFromConfirmed = (id) =>{
    UpdateStatus(id, "Pending", row.assignee)
  }

  const handleUpdateStatusConfirmed = (id) =>{
    if(assignee==="")
      UpdateStatus(id, "Confirmed", row.assignee)
    else
      UpdateStatus(id, "Confirmed", assignee)
  }

  const handleUpdateStatusArchived = (id) =>{
    UpdateStatus(id, "Archived", row.assignee)
  }

  const handleUpdateStatusOpen= (id) =>{
    UpdateStatus(id, "Open")
  }

  const UpdateStatus = async (id, status, chosenAssignee)=>{
    let updatingContact = {}
    if(chosenAssignee !== ""){
      updatingContact = {
        status: status,
        assignee: chosenAssignee
      }
    }else{
      updatingContact = {
        status: status
      }
    }

    try{
      await axios.put('https://fabackend.herokuapp.com/contacts/update-status/'+id,updatingContact)
      .then(async()=>{
        await delay(1300)
        setIsPushed(true)
      })
    }catch(err){
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    deleteOrder(row._id)
  }
  const deleteOrder = (id)=>{
    const requestOptions = {
      method: 'DELETE'
    };
  
    fetch("https://fabackend.herokuapp.com/contacts/" + id, requestOptions).then((res) => {
      if(res.status === 200){
        delay(1000)
        setIsDeleted(true)
        setIsDeleting(false)
      }
    })
  }
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '50%',
      transform: 'translate(-50%, -50%)',
      borderStyle: 'solid',
      background: 'transparent',
      borderRadius: '15px',
      backgroundColor: '#000',
    },
    overlay: {zIndex: 100}
  };

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    document.body.style.overflow = 'hidden';
}

function closeModal() {
    setIsDeleting(false);
    setIsDeleted(false);
    document.body.style.overflow = 'unset';
}

const onSelected = (e) =>{
  setAssignee(e.value)
  setErrorAssignee(false)
}

  return (

    <React.Fragment>
      {isDeleting?
        <Modal
          isOpen={isOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Delete Confirmation"
          ariaHideApp={false}
          shouldCloseOnOverlayClick={false}
        >
          <FormCover>
            <ExitButtonWrap>
              <LabelWapper><LabelLarge>Delete Confirmation</LabelLarge></LabelWapper>
              <Button onClick={closeModal}>X</Button>
            </ExitButtonWrap>
            <Form onSubmit={(e) => handleSubmit(e)}>
              <FormWapper>
                <LabelWapper><Label>Are you sure that you want to delete this order?</Label></LabelWapper>
              </FormWapper>
              <ButtonWrap>
                <EachButtonWrap>
                    <Button fontBig primary dark = {true}>Yes</Button>
                </EachButtonWrap>                
                <Button onClick={closeModal} fontBig primary dark = {true}>Cancel</Button>
              </ButtonWrap>
            </Form>
          </FormCover>
          
        </Modal>   
        :
        <></>
      }
      {isDeleted || isPushed?<></>:
      <>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} height="200px">
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {row.name}
          </TableCell>
          <TableCell style={{ width: 200 }} align="center">{row.phone}</TableCell>
          <TableCell align="center">{row.email}</TableCell>
          <TableCell align="center">{row.zipCode}</TableCell>
          <TableCell align="center">{row.projects}</TableCell>
          {isAdjustable && isOpen?
              <>
                <AssigneeCover align="center"  >
                <Dropdown options={options} onChange={onSelected} placeholder="None" />
                {
                    errorAssignee?
                    <button
                        onClick={()=>setErrorAssignee(false)}
                        className={`alert alert-danger`}
                        >
                        <strong>Assign a tutor!</strong>
                    </button>  :
                    <div></div>
                  
                }
              </AssigneeCover>
              <TableCell align="center">
                <ButtonWrapper>
                  <Button >Edit</Button>          
                  <Button onClick={() => handleUpdateStatusPending(row._id)}>Push</Button>
                  <Button onClick={() => handleDeleteOrder(row._id)}>Delete</Button>
                </ButtonWrapper>
              </TableCell>
            </>
            :
            <></>
          }
          {isAdjustable && (isPending)?
            <>
              <TableCell align="center">
              <Dropdown options={options} onChange={onSelected} value={row.assignee} placeholder="None" />
              </TableCell>
              <TableCell align="center">
                <ButtonWrapper>
                  <Button onClick={() => handleUpdateStatusConfirmed(row._id)}>Confirm</Button>
                  <Button onClick={() => handleUpdateStatusOpen(row._id)}>Pop</Button>
                </ButtonWrapper>        
              </TableCell>
            </>
              :
              <></>
          }
          {isAdjustable && (isConfirmed)?
              <><TableCell align="center">
                {row.assignee}
              </TableCell>
              <TableCell align="center">
              <ButtonWrapper>
                <Button onClick={() => handleUpdateStatusArchived(row._id)}>Archive</Button>
                <Button onClick={() => handleUpdateStatusPendingFromConfirmed(row._id)}>Pop</Button>
              </ButtonWrapper>        
            </TableCell></>
              :
              <></>
          }
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={15}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  Details
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Message</TableCell>
                      <TableCell>Packages</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        {row.message}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.packages}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
      }
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    phone: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default function CollapsibleTable({type,isAdjustable,isOpen, isConfirmed, isPending, isClosed}) {
    const [Orders, setOrders] = useState([])
    const [Users, setUsers]= useState([])
    const [loadingModal, setLoadingModal] = useState(false)

   
    useEffect(() => {
        setLoadingModal(true)
        loadDataOnlyOnce(); // this will fire only on first render
    }, [type]);
    async function loadDataOnlyOnce(){
        await fetch('https://fabackend.herokuapp.com/contacts')
        .then((response) => response.json())
        .then((data) => {
            if(isOpen){
              data = data.filter(order=>order.status === "Open")
            }
            else if(isPending){
              data = data.filter(order=>order.status === "Pending")
            }
            else if(isConfirmed){
              data = data.filter(order=>order.status === "Confirmed")
            }
            else if(isClosed){
              data = data.filter(order=>order.status === "Archived")
            }           
            setOrders(data)           
        });
        await fetch('https://fabackend.herokuapp.com/users')
        .then((response) => response.json())
        .then((data) => {         
            setUsers(data)           
            setLoadingModal(false)
        });
    }
  return (
    <CollapsibleTableContainer>
        {loadingModal?
            <LoadingModal isOpen={loadingModal}/>
            :
            <div></div>
        }
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
              <TableHead>
                  <TableRow>
                      <TableCell />
                      <TableCell>Name</TableCell>
                      <TableCell style={{ width: 200 }} align="center">Phone</TableCell>
                      <TableCell align="center">Email</TableCell>
                      <TableCell align="center">Zipcode</TableCell>
                      <TableCell align="center">Projects</TableCell>                  
                      {isAdjustable && !isConfirmed?
                      <TableCell align="center">Assigned Tutor</TableCell>:
                      <></>
                      }
                      {isAdjustable?
                      <TableCell align="center">Action</TableCell>:
                      <></>
                      }
                  </TableRow>
              </TableHead>
              <TableBody>
                  {Orders.map((order) => (
                      <Row key={order._id} users={Users}row={order} isAdjustable={isAdjustable} isOpen={isOpen} isConfirmed={isConfirmed} isPending={isPending} isClosed={isClosed}/>
                  ))}
              </TableBody>
          </Table>
        </TableContainer>
    </CollapsibleTableContainer>  
  );
}