
import styled from 'styled-components';

export const PricingSection = styled.div`
  padding: 100px 0 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: white;
  min-height: 1130px;
`;

export const PricingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  @media screen and (max-width: 1000px) {
    margin: 0 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const PricingHeading = styled.h1`
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode','Lucida Grande','Lucida Sans', Arial, sans-serif;
    box-shadow: 0 2px 5px black;
    text-transform: uppercase;
    font-size: 240%;
    padding: 10px 25px;
    margin: 0 auto 15px auto;
    display: inline-block;
    color: #fff;
    border-radius:20px;
    background-color:#000;
    margin-bottom: 24px;
`;

export const PricingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 350px;
  @media screen and (max-width: 900px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

  }
`;

export const PricingCard = styled.div`
  background: #242424;
  box-shadow: 0 6px 20px black;
  min-width: 350px;
  height: 570px;
  text-decoration: none;
  border-top-right-radius: 10px;
  border-top-left-radius: 40px;
  border-bottom-right-radius: 40px;
  border-bottom-left-radius: 10px;
  background-image: linear-gradient(gray,rgba(70,70,70,100) 30%, black);
  padding: 0 20px;
  &:nth-child(even) {
    margin: 24px;
  }
  &:hover {
    transform: scale(1.06);
    transition: all 0.3s ease-out;
    color: #1c2237;
  }
  @media screen and (max-width: 960px) {
    &:hover {
    transform: scale(1.02);
    transition: all 0.3s ease-out;
    color: #1c2237;
  }
  }
`;

export const PricingCardInfo = styled.div`
  display: flex;
  flex-direction: column;
  height: 500px;
  padding: 24px;
  align-items: center;
  color: #fff;
`;

export const PricingCardIcon = styled.div`
  margin: 24px 0;
  color: #01bf71;
`;

export const PricingCardPlan = styled.h3`
  margin-bottom: 5px;
  font-size: 36px;
  color: #01bf71;
`;

export const PricingCardCost = styled.h4`
  color: ${({orange}) => (orange ? '#DD7E28': '#fff')};
  font-size: 30px;
  margin-bottom: 20px;
`;

export const PricingCardLength = styled.p`
  font-size: 17px;
  margin-bottom: 12px;
`;

export const PricingCardFeatures = styled.ul`
  margin-right: 20px;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #a9b3c1;
`;

export const PricingCardFeature = styled.li`
  font-size: 17px;
  color: #fff;
  margin-bottom: 10px;
  margin-right: 10px ;
`;