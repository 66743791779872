import React , { useState}from 'react'
import Hero from '../components/HeroSection';
import InfoSection from '../components/InfoSection';
import { homeObjOne, homeObjTwo } from '../components/InfoSection/Data';
import Navbar from '../components/Navbar';
import Sidebar from '../components/SideBar';
import Profiles from '../components/Profiles';
import Contact from '../components/Contact';
import Package from '../components/Package';
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from '../components/Footer/Footer';
const Home = () => {
    const [isOpen, setIsOpen] = useState(false)
    const [packages, setPackages] = useState('comprehensive')
    const toggle = () => {
      setIsOpen(!isOpen)
    }

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle}/>
      <Navbar toggle={toggle}/>
      <Hero />
      <InfoSection {...homeObjOne}/>
      <InfoSection {...homeObjTwo}/>
      <Profiles />
      <Package setPackages = {setPackages}/>
      <Contact packages ={packages} setPackages={setPackages}/>
      <Footer />
    </>
  )
}

export default Home