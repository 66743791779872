import React, {useState} from 'react'
import CollapsibleTableReview from '../../components/Internal/components/ColapsibleTable/ColapsibleTableReviews'
import NavInternal from '../../components/Internal/NavInternal'
import SideBarInternal from '../../components/Internal/SideBarInternal'
import {OrderTableContainer, OrderTableLabel} from '../../components/Elements/OrderElements'
import "bootstrap/dist/css/bootstrap.min.css"

const Reviews = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }
  return (
    <>
      <SideBarInternal isOpen={isOpen} toggle={toggle}/>
      <NavInternal toggle={toggle}/>
      <OrderTableContainer>
        <OrderTableLabel>
            Reviews to approve
        </OrderTableLabel>
        <CollapsibleTableReview isAdjustable={true}/>
      </OrderTableContainer>
    </>
  )
}

export default Reviews